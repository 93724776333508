import React from "react";
import firebase, { User } from "firebase";
import { History } from "history";

import Sidebar, { Tab } from "./Sidebar/Sidebar";
import TrucksTab from "./TrucksTab/TrucksTab";
import MenuTab from "./MenuTab/MenuTab";

import "./Dashboard.css";
import ImageTab from "./ImageTab/ImageTab";
import { getAnnouncements, getUserData } from "../../api/firestore";
import { Spinner } from "../../components/Spinner/Spinner";
import { ToastContainer } from "react-toastify";
import SettingsTab from "./SettingsTab/SettingsTab";
import HelpTab from "./HelpTab/HelpTab";
import Announcement from "./Announcement/Announcement";

interface Props {
    history: History;
}
interface State {
    user: User;
    activeTab: string;
    loading: boolean;
    verified: boolean;
    truckIDs: string[];
    imageUrls: string[];
    announcements: string[];
    doneTutorial: boolean;
}

class Dashboard extends React.Component<Props, State> {
    readonly state: State = {
        user: {} as User,
        activeTab: Tab.Trucks,
        loading: true,
        verified: true,
        truckIDs: [],
        imageUrls: [],
        announcements: [],
        doneTutorial: false,
    };

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user: User | null) => {
            if (user) {
                if (!user.emailVerified) {
                    console.log("User needs to verify");
                    this.setState({
                        verified: false,
                        loading: false,
                    });
                    // Do not allow access to the dashboard
                } else {
                    // Get the user data
                    this.setState({
                        user,
                    });
                    getUserData(user).then((data) => {
                        if (data) {
                            let doneTutorial = true;
                            if (!data.doneTutorial) {
                                doneTutorial = false;
                            }
                            this.setState({
                                loading: false,
                                truckIDs: data.trucks,
                                imageUrls: data.images,
                                doneTutorial,
                            });
                        } else {
                            // Something went wrong
                        }
                    });
                    getAnnouncements().then((messages) => {
                        this.setState({ announcements: messages });
                    });
                }
            } else {
                // User is signed out.
                this.props.history.push("/login");
            }
        });
    }

    private changeContent = (tab: string) => {
        this.setState({ activeTab: tab });
    };

    private getActiveContent = () => {
        // Gets the current display for the active tab
        let activeTab: string = this.state.activeTab;
        if (activeTab === Tab.Trucks) {
            return <TrucksTab truckIDs={this.state.truckIDs} uid={this.state.user.uid} />;
        } else if (activeTab === Tab.Menu) {
            return <MenuTab uid={this.state.user.uid} />;
        } else if (activeTab === Tab.Images) {
            return (
                <ImageTab
                    uid={this.state.user.uid}
                    images={this.state.imageUrls}
                    truckIDs={this.state.truckIDs}
                />
            );
        } else if (activeTab === Tab.Settings) {
            return <SettingsTab uid={this.state.user.uid} />;
        } else if (activeTab === Tab.Help) {
            return <HelpTab />;
        } else {
            return <React.Fragment></React.Fragment>;
        }
    };

    private getEmailVerificationBox = (): JSX.Element => {
        return (
            <div className="panel">
                <h2 className="section-title">Welcome to PlateChasr!</h2>
                <p className="last-text">
                    It doesn't seem like your email is verified. Please{" "}
                    <a className="link" href="/verify">
                        verify
                    </a>{" "}
                    your email address.
                </p>
            </div>
        );
    };

    private getLoadingIndicator = (): JSX.Element => {
        return (
            <div className="loading-container">
                <div className="panel loading-background">
                    <Spinner />
                </div>
            </div>
        );
    };

    render() {
        let content: JSX.Element = this.state.verified
            ? this.getActiveContent()
            : this.getEmailVerificationBox();
        return (
            <div id="dashboard-container">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                />
                <Sidebar
                    doneTutorial={this.state.doneTutorial}
                    onTabClick={this.changeContent}
                    history={this.props.history}
                    uid={this.state.user.uid}
                />

                <div id="content">
                    {this.state.announcements.map((message) => (
                        <Announcement message={message} key={message} />
                    ))}
                    {this.state.loading ? this.getLoadingIndicator() : content}
                </div>
            </div>
        );
    }
}

export default Dashboard;
