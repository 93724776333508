import React from "react";
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";

import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import EmailVerification from "./pages/email-verification/EmailVerification";
import Dashboard from "./pages/dashboard/Dashboard";
import PasswordReset from "./pages/password-reset/PasswordReset";
import Landing from "./pages/landing/Landing";
import About from "./pages/about/About";
import TruckList from "./pages/truck-list/TruckList";

import firebase from "firebase";
import "bootstrap";
import "react-toastify/dist/ReactToastify.min.css";
import TruckOwners from "./pages/truck-owners/TruckOwners";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import TermsOfUse from "./pages/legal/TermsOfUse";

const firebaseConfig: object = {
    apiKey: "AIzaSyA3m8WcI64Brkk8blGdcH9-7-GlF2k_5Ws",
    authDomain: "platechasr2019.firebaseapp.com",
    databaseURL: "https://platechasr2019.firebaseio.com",
    projectId: "platechasr2019",
    storageBucket: "platechasr2019.appspot.com",
    messagingSenderId: "291205954102",
    appId: "1:291205954102:web:585841bed6e84ff128b697",
    measurementId: "G-82DQQV29B4",
};
firebase.initializeApp(firebaseConfig);

function App() {
    return (
        <React.Fragment>
            <title>PlateChasr</title>
            <Router history={createBrowserHistory()}>
                <Switch>
                    <Route path="/about" component={About} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/verify" component={EmailVerification} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/trucks" component={TruckList} />
                    <Route path="/reset" component={PasswordReset} />
                    <Route path="/truck-owners" component={TruckOwners} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-of-use" component={TermsOfUse} />
                    <Route path="/" component={Landing} />
                </Switch>
            </Router>
        </React.Fragment>
    );
}

export default App;
