import React from "react";
import firebase, { User } from "firebase";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import "./EmailVerification.css";

interface Props {}
interface State {
    resentEmail: boolean;
}

class EmailVerification extends React.Component<Props, State> {
    readonly state: State = {
        resentEmail: false,
    };

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user: User | null) => {
            if (user) {
                if (!user.emailVerified) {
                    // TODO: important, change continue url to actual domain
                    user.sendEmailVerification({ url: "https://platechasr.com/dashboard" })
                        .then(() => {
                            // email successfully sent
                            console.log("Email sent");
                        })
                        .catch((error: firebase.auth.Error) => {
                            console.error(error.code);
                            console.error(error.message);
                        });
                } else {
                    // user is already verified
                    console.log("User is already verified");
                    window.location.replace("https://platechasr.com/dashboard");
                }
            } else {
                // User is signed out.
            }
        });
    }

    private sendEmail = () => {
        firebase
            .auth()
            .currentUser?.sendEmailVerification({ url: "https://platechasr.com/dashboard" })
            .then(() => {
                // email sent
                this.setState({ resentEmail: true });
            });
    };

    render() {
        return (
            <div className="bg" id="verify-bg">
                <div className="section" id="verify-section">
                    <h2 className="section-title">Welcome!</h2>
                    <div className="section-content">
                        {this.state.resentEmail && (
                            <div className="alert-bg success-bg justify-content-start">
                                <IoIosCheckmarkCircleOutline className="alert-icon check-icon ml-0 mr-3" />
                                <p className="mb-0">It's been sent again!</p>
                            </div>
                        )}
                        <p className="text-high">
                            Check your email inbox for a verification email. It might take a minute.
                        </p>
                        <div className="d-flex justify-content-center w-100">
                            <button onClick={this.sendEmail} className="link-button">
                                Haven't received it yet?
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailVerification;
