import React from "react";
import { Dropdown, DropdownItem } from "../../../../components/Dropdown/Dropdown";
import { FoodTruck } from "../../../../types/FoodTruck";
import "./TruckEntry.css";

interface Props {
    truck: FoodTruck;
    onDelete: (truckID: string) => any;
    onDeploymentChange: (truckID: string, deployed: boolean) => any;
    onEdit: (truck: FoodTruck) => any;
}
interface State {}
class TruckEntry extends React.Component<Props, State> {
    readonly state = {};

    private formatShortDate = (date: Date): string => {
        return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    };

    private getStatus = (isDeployed: boolean): JSX.Element => {
        if (isDeployed) {
            return (
                <td className="status-pill-td">
                    <p className="success status-pill">DEPLOYED</p>
                </td>
            );
        }
        return (
            <td className="status-pill-td">
                <p className="warning status-pill">OFFLINE</p>
            </td>
        );
    };

    render() {
        let { truck } = this.props;
        let deploymentTitle: string = truck.deployed ? "Disable" : "Deploy";

        return (
            <tr className="table-row">
                <td
                    className="table-entry truck-table-name"
                    onClick={() => this.props.onEdit(truck)}
                >
                    {truck.name}
                </td>
                <td className="table-entry mobile-hide">
                    {this.formatShortDate(truck.dateCreated)}
                </td>
                {this.getStatus(truck.deployed)}
                <td className="dropdown-td">
                    <Dropdown title="Actions">
                        <DropdownItem title="Edit" onSelect={() => this.props.onEdit(truck)} />
                        <DropdownItem
                            title={deploymentTitle}
                            onSelect={() =>
                                this.props.onDeploymentChange(truck.truckID, !truck.deployed)
                            }
                        />
                        <DropdownItem
                            type="delete"
                            title="Delete"
                            onSelect={() => this.props.onDelete(truck.truckID)}
                        />
                    </Dropdown>
                </td>
            </tr>
        );
    }
}

export default TruckEntry;
