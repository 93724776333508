import React from "react";
import { Menu, Section, MenuItem, determineType } from "../../../../types/Menu";
import { createTimeRange, weekdays } from "../../../../utils/format";

import "./EntryInfo.css";

interface Props {
    entry: Menu | Section | MenuItem;
    onEdit: (entry: Menu | Section | MenuItem) => void;
    onDelete: (entry: Menu | Section | MenuItem) => void;
}
interface State {
    entryType: "menu" | "section" | "item" | "";
}

export default class EntryInfo extends React.Component<Props, State> {
    readonly state: State = {
        entryType: "",
    };

    private createInfoGroup = (
        key: number,
        label: string,
        content: JSX.Element,
        tight: boolean = false
    ): JSX.Element => {
        let colWidth: string = tight ? "col-md-3" : "col-md-6";
        return (
            <div key={key} className={"info-group col-6 " + colWidth}>
                <p className="label">{label}</p>
                {content}
            </div>
        );
    };

    private createDescriptionField = (entry: Section | MenuItem, key: number): JSX.Element => {
        // The description field is not required, so check if it exists
        if (entry.desc) {
            let desc: JSX.Element = <p className="description-field">{entry.desc}</p>;
            return this.createInfoGroup(key, "Description", desc);
        } else {
            // If there is no description field for this item, indicate as such
            let desc: JSX.Element = (
                <p className="description-field">There is no description for this item.</p>
            );
            return this.createInfoGroup(key, "Description", desc);
        }
    };

    private createPriceField = (entry: MenuItem, key: number): JSX.Element => {
        let formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });
        let price: JSX.Element = <p className="price-field">{formatter.format(entry.price)}</p>;
        return this.createInfoGroup(key, "Price", price, true);
    };

    private createCaloriesField = (entry: MenuItem, key: number): JSX.Element => {
        if (entry.calories) {
            let calories: JSX.Element = (
                <p className="calories-field">{entry.calories.toLocaleString()}</p>
            );
            return this.createInfoGroup(key, "Calories", calories, true);
        }
        // If there are no calories supplied for this item, return nothing
        return <React.Fragment key={key} />;
    };

    private createAvailabilityTable = (menu: Menu, key: number): JSX.Element => {
        let ranges: string[] = menu.hours.map((value) => createTimeRange(value.open, value.close));
        let hourLine: JSX.Element[] = [];
        for (let i = 0; i < 7; i++) {
            hourLine.push(
                <p key={i + "day"} className="hourline-day">
                    {weekdays[i]}
                </p>,
                <p key={i + "range"} className="hourline-range">
                    {ranges[i] || "Not Available"}
                </p>
            );
        }

        let availabilityInfoContainer: JSX.Element = (
            <div className="availability-info-container">{hourLine}</div>
        );
        return this.createInfoGroup(key, "Availability", availabilityInfoContainer);
    };

    render() {
        let title: string = this.props.entry?.name;
        let infoGroups: JSX.Element[] = [];
        let key: number = 0;
        let type: string = determineType(this.props.entry);

        if (type === "menu") {
            infoGroups.push(this.createAvailabilityTable(this.props.entry as Menu, key++));
        } else if (type === "section") {
            infoGroups.push(
                this.createDescriptionField(this.props.entry as Section | MenuItem, key++)
            );
        } else if (type === "menu item") {
            infoGroups.push(
                this.createDescriptionField(this.props.entry as Section | MenuItem, key++)
            );
            infoGroups.push(this.createPriceField(this.props.entry as MenuItem, key++));
            infoGroups.push(this.createCaloriesField(this.props.entry as MenuItem, key++));
        } else {
            return null;
        }

        return (
            <div className="panel">
                <div className="row-container">
                    <h2 className="section-title">{title}</h2>
                    <div className="entry-info-button-container">
                        <button
                            className="button-outline"
                            onClick={() => this.props.onEdit(this.props.entry)}
                        >
                            Edit
                        </button>
                        <button
                            style={{ marginLeft: 15 }}
                            className="delete-button"
                            onClick={() => this.props.onDelete(this.props.entry)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
                <div className="container">
                    <div className="row">{infoGroups}</div>
                </div>
            </div>
        );
    }
}
