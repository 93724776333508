import React from "react";
import Modal from "react-modal";
import { getFoodTrucks } from "../../../../api/firestore";
import { FoodTruck } from "../../../../types/FoodTruck";
import { Spinner } from "../../../../components/Spinner/Spinner";

interface Props {
    onClose: (url: string, imageMap: any) => any;
    uid: string;
    truckIDs: string[];
    url: string;
}
interface State {
    trucks: FoodTruck[];
    loading: boolean;
    imageMap: any;
}

export default class TruckLinkModal extends React.Component<Props, State> {
    readonly state: State = {
        trucks: [],
        loading: true,
        imageMap: {},
    };

    componentDidMount() {
        getFoodTrucks(this.props.truckIDs)
            .then((trucks: FoodTruck[] | null) => {
                if (trucks) {
                    let imageMap: any = {};
                    // set default truck
                    trucks.forEach((truck) => {
                        imageMap[truck.truckID] = [];
                    });
                    this.setState({
                        imageMap,
                        trucks,
                        loading: false,
                    });
                } else {
                    this.setState({
                        trucks: [],
                        loading: false,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private onCheckboxChange = (evt: any, truckID: string, imageType: string) => {
        let checked: boolean = evt.target.checked;
        if (checked) {
            this.setState((prevState: State) => {
                let newArray = [...prevState.imageMap[truckID], imageType];
                prevState.imageMap[truckID] = newArray;
                return {
                    imageMap: prevState.imageMap,
                };
            });
        } else {
            this.setState((prevState: State) => {
                let newArray = prevState.imageMap[truckID].filter(
                    (type: string) => type !== imageType
                );
                prevState.imageMap[truckID] = newArray;
                return {
                    imageMap: prevState.imageMap,
                };
            });
        }
    };

    private renderTable = () => {
        if (this.state.trucks.length === 0) {
            // show empty list component
        } else {
            return (
                <>
                    <table className="table gallery-table table-hover">
                        <thead>
                            <tr>
                                <th className="gallery-table-header">Truck</th>
                                <th className="gallery-table-header text-center">Thumbnail</th>
                                <th className="gallery-table-header text-center">Banner</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.trucks.map((truck) => {
                                return (
                                    <tr className="gallery-table-row" key={truck.truckID}>
                                        <td className="gallery-table-entry">{truck.name}</td>
                                        <td className="table-checkbox-entry">
                                            <label className="b-contain">
                                                <input
                                                    type="checkbox"
                                                    name={truck.truckID + "thumbnail"}
                                                    aria-label={truck.name + " thumbnail"}
                                                    onChange={(evt) =>
                                                        this.onCheckboxChange(
                                                            evt,
                                                            truck.truckID,
                                                            "thumbnail"
                                                        )
                                                    }
                                                />
                                                <div className="b-input"></div>
                                            </label>
                                        </td>
                                        <td className="table-checkbox-entry">
                                            <label className="b-contain">
                                                <input
                                                    type="checkbox"
                                                    name={truck.truckID + "banner"}
                                                    aria-label={truck.name + " banner"}
                                                    onChange={(evt) =>
                                                        this.onCheckboxChange(
                                                            evt,
                                                            truck.truckID,
                                                            "banner"
                                                        )
                                                    }
                                                />
                                                <div className="b-input"></div>
                                            </label>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <button type="submit" className="col-12">
                        Link
                    </button>
                </>
            );
        }
    };

    private cancelLink = () => {
        this.props.onClose("", {});
    };

    private onSubmit = (evt: any) => {
        evt.preventDefault();
        this.props.onClose(this.props.url, this.state.imageMap);
    };

    render() {
        return (
            <Modal
                isOpen={true}
                contentLabel="Link an image to a truck"
                overlayClassName="modal-bg"
                className="modal-box panel"
                closeTimeoutMS={150}
                ariaHideApp={false}
            >
                <div className="row-container">
                    <h2 className="section-title">Link to Truck</h2>
                    <button onClick={this.cancelLink} className="button-outline">
                        Cancel
                    </button>
                </div>
                <form onSubmit={this.onSubmit} method="POST" id="" className="form create-form">
                    <p>
                        Set this image as the banner or thumbnail image for your trucks. The
                        thumbnail image is displayed alongside your truck name in a list of truck.
                        The banner image is displayed to the user when they select your truck.
                    </p>
                    {this.state.loading ? <Spinner /> : this.renderTable()}
                </form>
            </Modal>
        );
    }
}
