import React from "react";
import "./Navbar.css";

interface Props {
    active?: "home" | "blog" | "about" | "trucks" | "owners";
}
interface State {
    atTop: boolean;
}

export default class Navbar extends React.Component<Props, State> {
    readonly state = {
        atTop: true,
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    private handleScroll = (event: any) => {
        if (window.scrollY <= 5) {
            this.setState({ atTop: true });
        } else {
            this.setState({ atTop: false });
        }
    };

    render() {
        let homeLinkClass: string = "";
        let aboutLinkClass: string = "";
        let blogLinkClass: string = "";
        let trucksLinkClass: string = "";
        let ownersLinkClass: string = "";
        if (this.props.active === "about") {
            aboutLinkClass = " active";
        } else if (this.props.active === "home") {
            homeLinkClass = " active";
        } else if (this.props.active === "blog") {
            blogLinkClass = " active";
        } else if (this.props.active === "trucks") {
            trucksLinkClass = " active";
        } else if (this.props.active === "owners") {
            ownersLinkClass = " active";
        }
        return (
            <nav
                className={`navbar ${
                    !this.state.atTop && "scrolled"
                } navbar-light fixed-top navbar-expand-md`}
            >
                <a className="navbar-brand" href="/">
                    <img src={require("../../assets/homepagelogo.png")} alt="PlateChasr logo" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className={"nav-item" + homeLinkClass}>
                            <a className="nav-link" href="/">
                                Home <span className="sr-only">(current)</span>
                            </a>
                        </li>
                        {/* <li className={"nav-item" + trucksLinkClass}>
                            <a className="nav-link" href="/trucks">
                                Trucks <span className="sr-only">(current)</span>
                            </a>
                        </li> */}
                        {/* <li className={"nav-item" + blogLinkClass}>
                            <a className="nav-link" href="/blog">Blog</a>
                        </li> */}
                        <li className={"nav-item" + aboutLinkClass}>
                            <a className="nav-link" href="/about">
                                About
                            </a>
                        </li>
                        <li className={"nav-item" + ownersLinkClass}>
                            <a className="nav-link" href="/truck-owners">
                                Truck Owners
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}
