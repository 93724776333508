import React from "react";
import TextTransition, { presets } from "react-text-transition";

import "./Landing.css";
import Navbar from "../../components/Navbar/Navbar";

import landingImage from "../../assets/landing.jpeg";
import appShowcase from "../../assets/showcase.png";
import foodTruckIcon from "../../assets/FoodTruckIcon.svg";
import Footer from "../../components/Footer/Footer";

interface Props {}
interface State {
    textIndex: number;
}

export default class Landing extends React.Component<Props, State> {
    readonly state = { textIndex: 0 };
    private textTick: NodeJS.Timeout | undefined;

    componentDidMount() {
        this.textTick = setInterval(
            () =>
                this.setState((prevState: State) => {
                    let newIndex: number = ++prevState.textIndex;
                    return { textIndex: newIndex };
                }),
            2000 // in ms, for text switch
        );
    }

    componentWillUnmount() {
        if (this.textTick) {
            clearInterval(this.textTick);
        }
    }

    private texts: string[] = [
        "Best",
        "Craziest",
        "Vegan",
        "Tastiest",
        "Friendliest",
        "Closest",
        "Happiest",
        "Wildest",
        "Cheapest",
        "Most Ethnic",
        "Gourmet",
        "Classiest",
        "Coolest",
        "Sweetest",
    ];

    render() {
        return (
            <React.Fragment>
                <Navbar active="home" />
                <div className="first-section landing-container">
                    <div className="landing-content" style={{ marginRight: 10 }}>
                        <h1>
                            Discover the
                            <div className="special-word">
                                <TextTransition
                                    text={this.texts[this.state.textIndex % this.texts.length]}
                                    springConfig={presets.gentle}
                                />
                            </div>
                            Food Trucks.
                        </h1>
                        <p className="subtitle">
                            Find food trucks near you with PlateChasr. View their menus, hours, and
                            locations with ease.
                            <br />
                            Join the platform today and explore the food trucks in your area.
                        </p>

                        <div className="mobile-button-wrapper">
                            <div className="mobile-button-container">
                                <a href="https://apps.apple.com/us/app/platechasr/id1531772290">
                                    <img
                                        className="mobile-button"
                                        id="apple-store"
                                        src={require("../../assets/MobileAppButtons/appstore.svg")}
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <p className="subtitle">
                            Are you a truck owner? <a href="/truck-owners">Learn more.</a>{" "}
                        </p>
                    </div>
                    <img alt="" className="background landing-img img-fluid" src={landingImage} />
                </div>
                <div className="app-showcase landing-container">
                    <img
                        alt="The PlateChasr app displayed on a phone"
                        className="background"
                        src={appShowcase}
                    />
                    <div className="showcase-content">
                        <h1>Get a curated list of trucks</h1>
                        <p className="subtitle">
                            Find your perfect flavor. Use PlateChasr to explore food trucks near
                            you.
                        </p>
                        {/* <button className="cta">Discover the List</button> */}
                    </div>
                </div>
                <div className="for-truck-owner landing-container">
                    <div className="truck-owner-content">
                        <h1>Are you a food truck owner?</h1>
                        <p className="subtitle">
                            Join the PlateChasr platform and reach more people.
                        </p>
                        <div className="truck-owner-cta-container">
                            <a href="/login" className="button-link button-outline-2">
                                Login
                            </a>
                            <a href="/register" className="button-link">
                                Register
                            </a>
                        </div>
                    </div>
                    <div className="foodtruck-svg-container">
                        <div className="circle-icon">
                            <img src={foodTruckIcon} alt="Food truck" />
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
