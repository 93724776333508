import React from "react";
import { IoIosArrowDown } from "react-icons/io";

import "./Dropdown.css";

interface DropdownProps {
    title: string;
    children: JSX.Element[] | JSX.Element;
}
interface State {
    open: boolean;
    height: number;
    width: number;
}

export class Dropdown extends React.Component<DropdownProps, State> {
    readonly state = { open: false, height: 0, width: 0 };
    domElement: HTMLButtonElement | null;

    constructor(props: Readonly<DropdownProps>) {
        super(props);
        this.domElement = null;
    }

    componentDidMount() {
        if (this.domElement) {
            if (!this.state.open) {
                this.setState({
                    height: this.domElement.clientHeight,
                    width: this.domElement.clientWidth
                });
            }
        }
    }

    private toggleMenu = (evt: any) => {
        if (!this.state.open) {
            document.addEventListener("click", this.toggleMenu);
        } else {
            document.removeEventListener("click", this.toggleMenu);
        }
        this.setState({ open: !this.state.open });
    };

    render() {
        return !this.state.open ? (
            <button
                className="dropdown-button"
                ref={el => {
                    this.domElement = el;
                }}
                onClick={this.toggleMenu}
            >
                {this.props.title}
                <IoIosArrowDown style={{ marginLeft: 5 }} />
            </button>
        ) : (
            <div
                style={{ height: this.state.height, width: this.state.width }}
                className="dropdown-item-container"
            >
                <div className="dropdown-menu-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

interface ItemProps {
    title: string;
    onSelect: () => void;
    type?: "delete" | "normal" | "download";
    link?: string;
    downloadName?: string;
}

export class DropdownItem extends React.Component<ItemProps, {}> {
    private onItemSelect = (evt: any) => {
        this.props.onSelect();
    };
    render() {
        if (this.props.type === "download") {
            return (
                <a
                    href={this.props.link}
                    download={this.props.downloadName}
                    className="button-link dropdown-item-button"
                >
                    {this.props.title}
                </a>
            );
        }
        let deleteClassName: string =
            this.props.type === "delete" ? "dropdown-item-delete" : "";
        return (
            <button
                className={`dropdown-item-button ${deleteClassName}`}
                onClick={this.onItemSelect}
            >
                {this.props.title}
            </button>
        );
    }
}
