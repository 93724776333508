import firebase, { User } from "firebase";
import {
    GeoCollectionReference,
    GeoFirestore,
    GeoQuery,
    GeoQuerySnapshot,
    GeoDocumentReference,
    GeoTransaction,
} from "geofirestore";
import { FoodTruck } from "../types/FoodTruck";
import { Menu, Section } from "../types/Menu";

export const getAnnouncements = (): Promise<string[]> => {
    return firebase
        .firestore()
        .collection("announcements")
        .get()
        .then((snapshot) => {
            return snapshot.docs
                .map((doc) => {
                    if (doc.exists) {
                        const announcement = doc.data();

                        return announcement.message;
                    }
                    return "";
                })
                .filter((message) => {
                    return message.length > 0;
                });
        });
};

export const getPublicTrucks = (
    center: { latitude: number; longitude: number },
    radius: number
): Promise<{ truck: FoodTruck; distance: number }[]> => {
    const geofirestore: GeoFirestore = new GeoFirestore(firebase.firestore());
    let geoCollection: GeoCollectionReference = geofirestore.collection("trucks");
    const query: GeoQuery = geoCollection.near({
        center: new firebase.firestore.GeoPoint(center.latitude, center.longitude),
        radius, // in km
    });

    return query
        .get()
        .then((value: GeoQuerySnapshot) => {
            value.docs.sort((a, b) => a.distance - b.distance);
            value.docs.map((doc) => {
                doc["data"] = doc["data"]();
                return doc;
            });

            let truckList: { truck: FoodTruck; distance: number }[] = [];
            value.docs.forEach((truckDoc) => {
                let truckData = truckDoc.data as any;
                let truck: FoodTruck = {
                    truckID: truckDoc.id,
                    name: truckData.name,
                    desc: truckData.desc,
                    foodCategories: truckData.foodCategories,
                    hours: truckData.hours,
                    phoneNumber: truckData.phoneNumber,
                    thumbnailUrl: truckData.thumbnailUrl,
                    bannerUrl: truckData.bannerUrl,
                    street: truckData.address.street,
                    city: truckData.address.city,
                    state: truckData.address.state,
                    postalCode: truckData.address.postalCode,
                    coordinates: truckData.coordinates,
                    menus: truckData.menus,
                    dateCreated: truckData.dateCreated,
                    deployed: truckData.deployed,
                };

                if (truck.deployed) {
                    truckList.push({ truck, distance: truckDoc.distance });
                }
            });
            return truckList;
        })
        .catch((err) => {
            console.error(err);
            return [] as { truck: FoodTruck; distance: number }[];
        });
};

export const createNewUser = (user: User | null, name: string, companyName: string): number => {
    if (user) {
        let userObject = {
            name,
            companyName,
            email: user.email,
            trucks: [], // list of truck ids owned by user
            images: [], // list of images owned by user
            doneTutorial: false,
        };
        firebase
            .firestore()
            .collection("truckOwners")
            .doc(user.uid)
            .set(JSON.parse(JSON.stringify(userObject)))
            .then(() => {
                return 0;
            })
            .catch((err) => {
                console.log(err);
                return -1;
            });
    }
    // Error if reached here
    return -1;
};

export const completedTutorial = (uid: string) => {
    firebase
        .firestore()
        .collection("truckOwners")
        .doc(uid)
        .set({ doneTutorial: true }, { merge: true });
};

// Get the doc from firestore for a truck owner
export const getUserDoc = (
    uid: string
): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> => {
    return firebase.firestore().collection("truckOwners").doc(uid);
};

// Get the data when a truck owner signs in
export const getUserData = (
    user: User | null
): Promise<firebase.firestore.DocumentData | undefined> => {
    if (user) {
        return getUserDoc(user.uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    return doc.data();
                } else {
                    // document does not exist
                    return undefined;
                }
            })
            .catch((err) => {
                console.log(err);
                return undefined;
            });
    }
    return Promise.resolve(undefined);
};
// handle the geocoding
const convertFirestoreToTruck = (geocodedData: any, id: string): FoodTruck | null => {
    if (geocodedData.d) {
        let data: any = geocodedData.d;
        let city: string = data.address.city;
        let postalCode: string = data.address.postalCode;
        let state: string = data.address.state;
        let street: string = data.address.street;
        let bannerUrl: string = data.bannerUrl;
        let desc: string = data.desc;
        let foodCategories: string[] = data.foodCategories;
        let hours: { open: string; close: string }[] = data.hours;
        let name: string = data.name;
        let phoneNumber: string = data.phoneNumber;
        let thumbnailUrl: string = data.thumbnailUrl;
        let latitude: number = data.coordinates.latitude;
        let longitude: number = data.coordinates.longitude;
        let deployed: boolean = data.deployed;
        let menus: string[] = data.menus;

        let truck: FoodTruck = {
            truckID: id,
            city,
            postalCode,
            state,
            street,
            bannerUrl,
            desc,
            foodCategories,
            hours,
            name,
            phoneNumber,
            thumbnailUrl,
            deployed,
            coordinates: { latitude, longitude },
            dateCreated: new Date(),
            menus,
        };
        return truck;
    }

    // Something went wrong with the encoding on firestore
    // or the truck was not uploaded properly with the hashed location id
    return null;
};

// Get one truck from id
export const getFoodTruck = (id: string): Promise<FoodTruck | null> => {
    return firebase
        .firestore()
        .collection("trucks")
        .doc(id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                return doc.data();
            }
            return null;
        })
        .then((data) => {
            if (data) {
                return convertFirestoreToTruck(data, id);
            }
            return null;
        })
        .catch((err) => {
            console.error(err);
            return null;
        });
};

// Get list of trucks from id array in one operation
export const getFoodTrucks = (ids: string[]): Promise<FoodTruck[] | null> => {
    let truckRefs = ids.map((id: string) => {
        return firebase.firestore().collection("trucks").doc(id).get();
    });
    return Promise.all(truckRefs).then((docs) => {
        // Get data
        let trucksData: (FoodTruck | null)[] = docs.map((doc) => {
            if (doc.exists) {
                return convertFirestoreToTruck(doc.data(), doc.id);
            }
            return null;
        });

        // Remove null values from trucks array
        return trucksData.filter((truck) => truck) as FoodTruck[];
    });
};

// Use the geofirestore package to encode location into firestore
const addFoodTruckGeoCode = (truck: FoodTruck, uid: string): Promise<string | null> => {
    const geofirestore: GeoFirestore = new GeoFirestore(firebase.firestore());
    let geoCollection: GeoCollectionReference = geofirestore.collection("trucks");

    // This removes the distance, coordinates, address, and id field
    // The coordinates and address fields need to be constructed manually
    // The id field is stored as the document id
    let { coordinates, truckID, street, city, state, postalCode, ...truckObject } = truck;
    let coords = new firebase.firestore.GeoPoint(
        truck.coordinates.latitude,
        truck.coordinates.longitude
    );

    return geoCollection
        .add({
            ...truckObject,
            address: {
                street,
                city,
                state,
                postalCode,
            },
            coordinates: coords,
            owner: uid,
        })
        .then((docRef: GeoDocumentReference) => {
            return docRef.native.id;
        })
        .catch((err: any) => {
            console.error(err);
            return null;
        });
};

// Add a truck to the truck owner's database entry
export const addFoodTruck = (uid: string, truck: FoodTruck): Promise<string> => {
    // TODO: eventually implement this operation with batch writing
    // First, add to firestore with geofirestore
    return addFoodTruckGeoCode(truck, uid)
        .then((truckID: string | null) => {
            if (truckID) {
                return getUserDoc(uid)
                    .update({
                        trucks: firebase.firestore.FieldValue.arrayUnion(truckID),
                    })
                    .then((_) => {
                        // It probably worked
                        return truckID;
                    });
            }
            return "";
        })
        .catch((err) => {
            console.error(err);
            return "";
        });
};

// Update a truck
export const updateFoodTruck = (uid: string, truck: FoodTruck): Promise<boolean> => {
    const geofirestore: GeoFirestore = new GeoFirestore(firebase.firestore());
    let geoCollection: GeoCollectionReference = geofirestore.collection("trucks");

    let truckOwnerRef = firebase.firestore().collection("truckOwners").doc(uid);
    let truckRef = firebase.firestore().collection("trucks").doc(truck.truckID);

    return geofirestore.runTransaction((transaction) => {
        const geotransaction = new GeoTransaction(transaction);

        return geotransaction
            .get(truckOwnerRef)
            .then((truckOwnerDoc) => {
                if (!truckOwnerDoc.exists) {
                    throw new Error("Truck owner does not exist");
                }

                let data = truckOwnerDoc.data();
                let ownedTrucks: string[] = [];
                if (data) {
                    ownedTrucks = data.trucks;
                }

                if (!ownedTrucks.includes(truck.truckID)) {
                    throw new Error("Truck owner does not have authorization to update this truck");
                }

                let {
                    coordinates,
                    truckID,
                    street,
                    city,
                    state,
                    postalCode,
                    ...truckObject
                } = truck;
                let coords = new firebase.firestore.GeoPoint(
                    truck.coordinates.latitude,
                    truck.coordinates.longitude
                );

                geotransaction.update(truckRef, {
                    ...truckObject,
                    address: {
                        street: truck.street,
                        city: truck.city,
                        state: truck.state,
                        postalCode: truck.postalCode,
                    },
                    coordinates: coords,
                });
            })
            .then(() => {
                // successful
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

// Remove a truck from the owner's database entry
export const removeFoodTruck = (uid: string, truckID: string): Promise<boolean> => {
    let truckOwnerRef = firebase.firestore().collection("truckOwners").doc(uid);
    let truckRef = firebase.firestore().collection("trucks").doc(truckID);
    let deletedTruck = firebase.firestore().collection("deletedTrucks").doc(truckID);
    // This is quite an expensive operation
    // First of all, we have to check if the truck owner even owns the truck
    // they're trying to delete
    // A transaction is used to make the operation atomic
    return firebase.firestore().runTransaction((transaction: firebase.firestore.Transaction) => {
        return transaction
            .get(truckRef)
            .then((truckDoc) => {
                if (!truckDoc.exists) {
                    throw "Truck does not exist";
                }

                const data = truckDoc.data();

                // Set deletion flag
                transaction.update(truckRef, {
                    SixFeet: true,
                });

                // Move to deletedTrucks collection
                console.log(data);
                transaction.set(deletedTruck, {
                    ...data,
                });
            })
            .then(() => {
                // transaction successful
                // delete from trucks/
                return truckRef.delete();
            })
            .then(() => {
                // remove from owner array
                return truckOwnerRef.update({
                    trucks: firebase.firestore.FieldValue.arrayRemove(truckID),
                });
            })
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

export const changeTruckDeployment = (
    uid: string,
    truckID: string,
    deployed: boolean
): Promise<boolean> => {
    let truckOwnerRef = firebase.firestore().collection("truckOwners").doc(uid);
    let truckRef = firebase.firestore().collection("trucks").doc(truckID);

    // This is another expensive operation
    // First of all, we have to check if the truck owner even owns the truck
    // they're trying to delete
    // A transaction is used to make the operation atomic
    return firebase.firestore().runTransaction((transaction: firebase.firestore.Transaction) => {
        return transaction
            .get(truckOwnerRef)
            .then((truckOwnerDoc) => {
                if (!truckOwnerDoc.exists) {
                    throw "Truck owner does not exist";
                }

                // Check for ownership before proceeding
                let data = truckOwnerDoc.data();
                let ownedTrucks: string[] = [];
                if (data) {
                    ownedTrucks = data.trucks;
                }

                if (!ownedTrucks.includes(truckID)) {
                    throw "Truck owner does not have authorization to delete this truck";
                }

                // Update the field in the trucks collection
                transaction.update(truckRef, {
                    "d.deployed": deployed,
                });
            })
            .then(() => {
                // successful
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

export const linkMenuToTruck = (uid: string, truckID: string, menuID: string): Promise<boolean> => {
    let truckRef = firebase.firestore().collection("trucks").doc(truckID);
    let menuRef = firebase
        .firestore()
        .collection("truckOwners")
        .doc(uid)
        .collection("menu")
        .doc(menuID);

    return firebase.firestore().runTransaction((transaction) => {
        return transaction
            .get(menuRef)
            .then((menuDoc) => {
                if (!menuDoc.exists) {
                    throw "Menu does not exist";
                }

                let menuPath = `${uid}/${menuDoc.id}`;
                transaction.update(truckRef, {
                    menus: firebase.firestore.FieldValue.arrayUnion(menuPath),
                });
            })
            .then(() => {
                return true;
            });
    });
};

export const addImageToUser = (uid: string, imageUrl: string): Promise<string> => {
    return getUserDoc(uid)
        .update({
            images: firebase.firestore.FieldValue.arrayUnion(imageUrl),
        })
        .then(() => {
            // It probably worked
            return imageUrl;
        })
        .catch((err) => {
            throw err;
        });
};

export const removeImageFromUser = (uid: string, imageUrl: string): Promise<any> => {
    return getUserDoc(uid)
        .update({
            images: firebase.firestore.FieldValue.arrayRemove(imageUrl),
        })
        .then(() => {
            return true;
        })
        .catch((err) => {
            throw err;
        });
};

export const addImageToTruck = (
    uid: string,
    truckID: string,
    imageUrl: string,
    thumbnail: boolean,
    banner: boolean
): Promise<boolean> => {
    let truckOwnerRef = firebase.firestore().collection("truckOwners").doc(uid);
    let truckRef = firebase.firestore().collection("trucks").doc(truckID);

    // Expensive operation, check if the truck owner owns the truck before making the change
    return firebase.firestore().runTransaction((transaction: firebase.firestore.Transaction) => {
        return transaction
            .get(truckOwnerRef)
            .then((truckOwnerDoc) => {
                if (!truckOwnerDoc.exists) {
                    throw new Error("Truck owner does not exist");
                }

                // Check for ownership before proceeding
                let data = truckOwnerDoc.data();
                let ownedTrucks: string[] = [];
                if (data) {
                    ownedTrucks = data.trucks;
                }

                if (!ownedTrucks.includes(truckID)) {
                    throw new Error("Truck owner does not have authorization to delete this truck");
                }

                // Update the field in the trucks collection
                let updateObject: any = {};
                if (thumbnail) {
                    updateObject["d.thumbnailUrl"] = imageUrl;
                }
                if (banner) {
                    updateObject["d.bannerUrl"] = imageUrl;
                }

                transaction.update(truckRef, updateObject);
            })
            .then(() => {
                // successful
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};
