import React from "react";
import { FaInstagram, FaHeart, FaTwitter } from "react-icons/fa";
import "./Footer.css";

export default class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="container mt-3" id="footer-content">
                    <div className="row justify-content-between" id="footer-top-content">
                        <div className="col-md-6 col-lg-3 col-sm-6 col-6 align-items-center d-flex">
                            <img
                                id="footer-logo"
                                src={require("../../assets/homepagelogo.png")}
                                alt="PlateChasr"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                            <div id="footer-pages" className="text-right">
                                <a href="/terms-of-use">Terms of Use</a>
                                <a href="/privacy-policy">Privacy Policy</a>
                                <a href="/about">About</a>
                            </div>
                        </div>
                    </div>
                    <hr className="w-100" />
                    <div
                        className="row d-flex flex-md-row-reverse justify-content-md-between"
                        id="footer-bottom-text"
                    >
                        <div
                            id="social-links"
                            className="col-sm-12 mr-2 mb-2 d-flex flex-direction-row"
                        >
                            <a
                                href="https://www.instagram.com/platechasr"
                                className="social-link mr-2"
                            >
                                <FaInstagram className="social-link" size={35} />
                            </a>
                            <a href="https://www.twitter.com/platechasr" className="social-link">
                                <FaTwitter className="social-link" size={35} />
                            </a>
                        </div>
                        <p className="flex-grow-1 col">
                            © {new Date().getFullYear()}, Made with{" "}
                            <FaHeart
                                style={{
                                    marginBottom: 3,
                                }}
                            />{" "}
                            by PlateChasr
                        </p>
                    </div>
                </div>
            </footer>
        );
    }
}
