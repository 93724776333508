import React from 'react';
import './TruckFilter.css';
import { FoodCategory } from '../../../types/FoodTruck';

interface Props {
    onRadiusChange: (radius: number) => any,
    onFilterChange: (categories: string[], search: string) => any,
};
interface State {
    range: number,
    search: string,
    selectedCategories: string[],
};

export default class TruckFilter extends React.Component<Props, State> {
    readonly state: State = {
        range: 50,
        search: '',
        selectedCategories: []
    };

    private onRangeChange = (evt: any) => {
        let range: number = evt.target.value
        this.setState(() => {
            this.props.onRadiusChange(range);
            return {
                range,
            }
        })
    }

    private onSearchChange = (evt: any) => {
        let search: string = evt.target.value;
        this.setState((prevState: State) => {
            this.props.onFilterChange(prevState.selectedCategories, search);
            return {
                search,
            }
        });
    }

    private onCategoryClick = (category: string) => {
        this.setState((prevState: State) => {
            let newArray: string[] = [];

            if (prevState.selectedCategories.includes(category)) {
                newArray = prevState.selectedCategories.filter(cat => cat !== category)
            } else {
                newArray = [...prevState.selectedCategories, category];
            }

            this.props.onFilterChange(newArray, prevState.search);
            return {
                selectedCategories: newArray
            }
        })
    }

    private renderCategories = () => {
        return FoodCategory.map((category: string) => {
            let className: string = "tag";
            if (this.state.selectedCategories.includes(category)) {
                className += " active";
            }
            return (
                <button 
                    key={category}
                    className={className}
                    onClick={() => this.onCategoryClick(category)}
                >
                    {category}
                </button>
            )
        })
    }

    render() {
        return (
            <div id="filter-container">
                <h1 id="filter-title">Filter</h1>
                <input
                    id="filter-input"
                    type="text"
                    aria-label="Search"
                    placeholder="Search..."
                    onChange={this.onSearchChange}
                    value={this.state.search}
                />
                <div className="form-field" id="radius-form-field">
                    <label id="radius-label">
                        <div>
                            Search Distance 
                            <p>{this.state.range} mi</p>
                        </div>
                        <input 
                            className="slider"
                            type="range"
                            min="10"
                            max="100"
                            step="5"
                            onChange={this.onRangeChange}
                            value={this.state.range}
                        />
                    </label>
                </div>
                <div className="form-field mb-0">
                    <label id="category-label" onClick={(evt) => evt.preventDefault()}>
                        Categories
                        <div id="filter-category-container">
                            {this.renderCategories()}
                        </div>
                    </label>
                </div>
            </div>
        )
    }
}