import React from "react";
import { toast } from "react-toastify";
import { uploadImage, getImageDataFromURL, deleteImage } from "../../../api/firesbase-storage";
import { addImageToUser, removeImageFromUser, addImageToTruck } from "../../../api/firestore";
import ImageGallery from "./ImageGallery";
import TruckLinkModal from "./LinkModals/TruckLinkModal";
import MenuLinkModal from "./LinkModals/MenuLinkModal";
import { addImageToMenuItem } from "../../../api/firestore-menus";
let FoodTruckIcon = require("../../../assets/FoodTruckIcon.svg");

interface Props {
    uid: string;
    images: string[];
    truckIDs: string[];
}
interface State {
    images: { name: string; url: string; path: string; dateUploaded: string }[];
    loading: boolean;
    linkingToTruck: boolean;
    linkingToMenuItem: boolean;
    selectedUrl: string;
}

export default class ImageTab extends React.Component<Props, State> {
    readonly state: State = {
        loading: true,
        images: [],
        linkingToTruck: false,
        linkingToMenuItem: false,
        selectedUrl: "",
    };

    componentDidMount() {
        if (this.props.images.length > 0) {
            this.props.images.forEach((url) => {
                getImageDataFromURL(url)
                    .then((data) => {
                        this.setState((prevState: State) => {
                            return { images: [...prevState.images, data] };
                        });
                    })
                    .then(() => {
                        this.setState({ loading: false });
                    });
            });
        } else {
            this.setState({ loading: false });
        }
    }

    private getEmptyListComponent = (): JSX.Element => {
        if (!this.state.loading) {
            return (
                <div
                    className="container d-flex flex-column align-items-center"
                    id="empty-list-container"
                >
                    <div className="circle-icon">
                        <img src={FoodTruckIcon} alt="No images found" />
                    </div>
                    <h2>Oops! You don't seem to have any images.</h2>
                    <button onClick={this.openFilePicker}>Upload images</button>
                </div>
            );
        } else {
            return <></>;
        }
    };

    private openFilePicker = () => {
        let imageUploader = document.getElementById("image-uploader") as HTMLInputElement;
        if (imageUploader) {
            imageUploader.click();
            onchange = (event: any) => {
                if (event.target) {
                    const fileList = event.target.files;
                    this.uploadFiles(fileList);
                }
            };
        }
    };

    private uploadFiles = (fileList: FileList) => {
        for (let index = 0; index < fileList.length; index++) {
            const file = fileList[index];

            uploadImage(file, this.props.uid, () => {}, this.onImageUpload);
        }
    };

    private onImageUpload = (imageData: any) => {
        addImageToUser(this.props.uid, imageData.url).then(() => {
            toast.success("Image uploaded");
            this.setState((prevState: State) => {
                return {
                    images: [...prevState.images, imageData],
                };
            });
        });
    };

    private deleteImage = (url: string) => {
        // remove image from storage
        deleteImage(url, this.props.uid)
            .then(() => {
                // remove image from user db
                removeImageFromUser(this.props.uid, url)
                    .then((isSuccessful: boolean) => {
                        if (!isSuccessful) {
                            throw "Something went wrong";
                        }
                        // remove image from view
                        toast.success("Deletion successful");
                        this.setState((prevState: State) => {
                            return {
                                images: prevState.images.filter((image) => {
                                    return image.url !== url;
                                }),
                            };
                        });
                    })
                    .catch((err) => {
                        throw err;
                    });
            })
            .catch((err) => {
                console.error(err);
                toast.error("Something went wrong");
            });
    };

    private linkImageToTruck = (url: string, imageMap: any) => {
        // image map should be in the following format
        // {
        //     [id]: ['thumbnail', 'banner'],
        //      ...
        // }
        //
        const truckIDs = Object.keys(imageMap);
        truckIDs.forEach((truckID) => {
            const imageTypes = imageMap[truckID];
            const setThumbnail = imageTypes.includes("thumbnail");
            const setBanner = imageTypes.includes("banner");
            addImageToTruck(this.props.uid, truckID, url, setThumbnail, setBanner);
        });
    };

    private linkImageToItems = (url: string, menuItems: string[]) => {
        // each item will be a / separated string of menu, section, and item ids
        menuItems.forEach((path) => {
            const tokens = path.split("/");
            addImageToMenuItem(this.props.uid, tokens[0], tokens[1], tokens[2], url).then(() => {
                toast.success("Image linked!");
            });
        });
    };

    private openTruckLinkModal = (url: string) => {
        this.setState({ linkingToTruck: true, selectedUrl: url });
    };

    private openMenuLinkModal = (url: string) => {
        this.setState({ linkingToMenuItem: true, selectedUrl: url });
    };

    private closeTruckLinkModal = (url: string, imageMap: any) => {
        // check if it wasnt a cancel
        if (url.length > 0 && imageMap !== {}) {
            // publish
            this.linkImageToTruck(url, imageMap);
        }
        // in any case, close the modal
        this.setState({ linkingToTruck: false });
    };

    private closeMenuLinkModal = (url: string, menuItems: string[]) => {
        // check if it wasn't a cancel
        if (url.length > 0 && menuItems.length > 0) {
            // publish
            this.linkImageToItems(url, menuItems);
        }
        this.setState({ linkingToMenuItem: false });
    };

    render() {
        return (
            <div className="panel">
                <input // hidden file picker
                    id="image-uploader"
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    accept="image/*"
                />

                <div className="row-container">
                    <h2 className="section-title">Images</h2>
                    <button onClick={this.openFilePicker} className="button-outline">
                        Upload
                    </button>
                </div>
                {this.state.images.length === 0 ? (
                    this.getEmptyListComponent()
                ) : (
                    <>
                        <ImageGallery
                            images={this.state.images}
                            deleteImage={this.deleteImage}
                            onTruckLinkSelect={this.openTruckLinkModal}
                            onMenuLinkSelect={this.openMenuLinkModal}
                        />
                        {this.state.linkingToTruck && (
                            <TruckLinkModal
                                onClose={this.closeTruckLinkModal}
                                uid={this.props.uid}
                                truckIDs={this.props.truckIDs}
                                url={this.state.selectedUrl}
                            />
                        )}
                        {this.state.linkingToMenuItem && (
                            <MenuLinkModal
                                onClose={this.closeMenuLinkModal}
                                uid={this.props.uid}
                                url={this.state.selectedUrl}
                            />
                        )}
                    </>
                )}
            </div>
        );
    }
}
