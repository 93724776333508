import React from "react";
import { History } from "history";
import { FaTruck } from "react-icons/fa";

import Navbar from "../../components/Navbar/Navbar";
import RegisterForm from "../../components/RegisterForm/RegisterForm";
import "./TruckOwners.css";
import Footer from "../../components/Footer/Footer";

interface Props {
    history: History;
}

export default class TruckOwners extends React.Component<Props> {
    render() {
        return (
            <React.Fragment>
                <Navbar active="owners" />
                <div className="container owner-container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 mb-4">
                            <div className="alert alert-info">
                                <FaTruck size={30} className="mb-2 mr-2" />
                                <h4 className="alert-heading" style={{ display: "inline" }}>
                                    Does your truck need work?
                                </h4>
                                <p className="mb-0">
                                    Our friends at{" "}
                                    <a
                                        className="business-name"
                                        href="https://www.instagram.com/buildafoodtruckmiami/"
                                    >
                                        <strong>Build Your Food Truck Miami</strong>
                                    </a>{" "}
                                    can help you with any work your truck needs. Let them know
                                    PlateChasr sent you!
                                </p>
                            </div>
                            <h1>Let us help you</h1>
                            <p>
                                You've put so much into your food truck, let us help you reach more
                                people.
                            </p>
                            <p>
                                With PlateChasr, you can list your location, menus, images, and
                                hours with so much more coming in the future.
                            </p>
                            <p>
                                Best of all, it's <strong>free</strong>. We won't ask for your
                                credit card information. You already have a lot on your plate.
                            </p>
                            <img className="w-100" src={require("../../assets/Screenshots.png")} />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="landing-register-form">
                                <h3>Register</h3>
                                <RegisterForm history={this.props.history} />
                                <a
                                    href="https://platechasr.com/login"
                                    className="link text-center w-100 mt-2 d-block"
                                >
                                    or login
                                </a>
                            </div>
                            <p className="mt-2 d-block">
                                If you need any help, feel free to reach out on{" "}
                                <a href="https://instagram.com/platechasr">Instagram</a>,{" "}
                                <a href="https://twitter.com/platechasr">Twitter</a>, or email{" "}
                                <a href="mailto:info@platechasr.com">info@platechasr.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
