import React from "react";
import { Img } from "react-image";
import { Spinner } from "../../../components/Spinner/Spinner";
import "./ImageGallery.css";
import { Dropdown, DropdownItem } from "../../../components/Dropdown/Dropdown";

interface Props {
    url: string;
    name: string;
    date: string;
    onDelete: (url: string) => any;
    onTruckLink: (url: string) => any;
    onMenuLink: (url: string) => any;
}
interface State {}

export default class ImageEntry extends React.Component<Props, State> {
    private openLightbox = () => {
        let thumbnail = document.getElementById(this.props.url);
        if (thumbnail) {
            thumbnail.click();
        }
    };

    render() {
        let formattedDate = new Date(this.props.date).toLocaleString();
        return (
            <tr className="gallery-table-row">
                <td onClick={this.openLightbox}>
                    <Img
                        id={this.props.url}
                        src={this.props.url}
                        loader={<Spinner />}
                        className="gallery-image"
                    />
                </td>
                <td
                    className="gallery-table-entry gallery-table-name"
                    style={{ cursor: "pointer" }}
                    onClick={this.openLightbox}
                >
                    {this.props.name}
                </td>
                <td className="gallery-table-entry">{formattedDate}</td>
                <td className="gallery-table-entry">
                    <Dropdown title="Actions">
                        <DropdownItem
                            title="Link to Menu"
                            onSelect={() => this.props.onMenuLink(this.props.url)}
                        />
                        <DropdownItem
                            title="Link to Truck"
                            onSelect={() => this.props.onTruckLink(this.props.url)}
                        />
                        <DropdownItem
                            type="delete"
                            title="Delete"
                            onSelect={() => {
                                this.props.onDelete(this.props.url);
                            }}
                        />
                    </Dropdown>
                </td>
            </tr>
        );
    }
}
