export type Menu = {
    name: string,
    sections: Section[],
    hours: {open: string, close: string}[],
    id: string,
};

export type Section = {
    name: string,
    desc?: string,
    items: MenuItem[],
    id: string,
}

export type MenuItem = {
    name: string,
    id: string,
    price: number,
    desc?: string,
    calories?: number,
    photoURL?: string,
}

export type Entry = Menu | Section | MenuItem;


function determineIfMenu (entry: Menu | Section | MenuItem | null): entry is Menu {
    if (entry) {
        if ((entry as Menu).sections) {
            return true;
        }
    }
    return false;
}
function determineIfSection (entry: Menu | Section | MenuItem | null): entry is Section {
    if (entry) {
        if ((entry as Section).items) {
            return true;
        }
    }
    return false;
}
function determineIfItem (entry: Menu | Section | MenuItem | null): entry is MenuItem {
    if (entry) {
        if ((entry as MenuItem).price) {
            return true;
        }
    }
    return false;
}

export const determineType = (item: Menu | Section | MenuItem | null): 'menu' | 'section' | 'menu item' | '' => {
    if (determineIfMenu(item)) {
        return 'menu';
    } else if (determineIfSection(item)) {
        return 'section';
    } else if (determineIfItem(item)) {
        return 'menu item';
    } else {
        return ''; // null
    }
}