import React from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { IoIosInformationCircleOutline, IoIosAdd, IoIosTrash } from 'react-icons/io';

import { Menu } from '../../../../types/Menu';
import AvailabilityRule from './AvailabilityRule';
import './CreateMenu.css';

interface Props {
    onClose: (truck: Menu | null, edit: boolean) => void,
    itemToEdit: Menu | null,
};
interface State {
    name: string,
    hours: {open: string, close: string, editedBy: number}[],
    numAvailabilityRules: number,
};

class CreateMenu extends React.Component<Props, State> {
    readonly state = {
        name: '',
        hours: [{open: '', close: '', editedBy: 0}, {open: '', close: '', editedBy: 0}, {open: '', close: '', editedBy: 0}, {open: '', close: '', editedBy: 0}, {open: '', close: '', editedBy: 0}, {open: '', close: '', editedBy: 0}, {open: '', close: '', editedBy: 0}],
        numAvailabilityRules: 1
    };

    componentDidMount() {
        let itemToEdit: Menu | null = this.props.itemToEdit;
        if (itemToEdit) {
            let numAvailabilityRules: number = this.getAmountOfAvailabilityRules(itemToEdit.hours)
            let newRules: {open: string, close: string, editedBy: number}[] = this.getRulesWithEditedBy(itemToEdit.hours);
            console.log(newRules);
            this.setState({
                numAvailabilityRules,
                name: itemToEdit.name,
                hours: newRules,
            })
        }
    }

    private isEmpty = (rule: {open: string, close: string}): boolean => {
        return rule.open === '' && rule.close === '';
    }

    private getRulesWithEditedBy = (rules: {open: string, close: string}[]): {open: string, close: string, editedBy: number}[] => {
        let newRules: {open: string, close: string, editedBy: number}[] = [];
        // The index of ruleCheck will serve as the author of the rule
        let ruleCheck: {open: string, close: string}[] = [];
        rules.forEach((rule, index) => {
            // If the ruleset is empty, it can be left alone
            if (this.isEmpty(rule)) {
                newRules.push({
                    open: '',
                    close: '',
                    editedBy: 0,
                });
                return;
            }

            // Check if it is in ruleCheck already
            // It's not possible to use includes() since its a shallow check
            let existsInRuleCheck: boolean = false;
            let ruleCheckIndex: number;
            for (ruleCheckIndex = 0; ruleCheckIndex < ruleCheck.length; ruleCheckIndex++) {
                let check: {open: string, close: string} = ruleCheck[ruleCheckIndex];
                if ((check.open === rule.open) && (check.close === rule.close)) {
                    existsInRuleCheck = true;
                    break;
                }
            }
            if (!existsInRuleCheck) {
                // push() appends to the end and returns the new length
                // allowing us to get the resultant index
                ruleCheckIndex = ruleCheck.push(rule) - 1;
            }

            // editedBy becomes the index of where the rule was found
            let newRule = {
                open: rule.open,
                close: rule.close,
                editedBy: ruleCheckIndex + 1,
            }
            newRules.push(newRule);
        })
        return newRules;
    }

    private getAmountOfAvailabilityRules = (hours: {open: string, close: string}[]): number => {
        // We need an algorithm to parse the hours and convert them to active
        // buttons and num availability rules
        // The algorithm will find the amount of times different open and close
        // times are found across the hours. The max of the two numbers is
        // the amount of availability rules that should be created.
        let numDeviationsOpen: number = 0;
        let numDeviationsClose: number = 0;
        let openTimes: string[] = [];
        let closeTimes: string[] = [];
        hours.forEach((hour) => {
            if (this.isEmpty(hour)) {
                return;
            }
            if (!openTimes.includes(hour.open)) {
                openTimes.push(hour.open);
                numDeviationsOpen++;
            }
            if (!closeTimes.includes(hour.close)) {
                closeTimes.push(hour.close);
                numDeviationsClose++;
            }
        });
        return Math.max(numDeviationsClose, numDeviationsOpen);
    }

    private publishMenu = (evt: any) => {
        evt.preventDefault();
        let edit: boolean = false;
        let menu: Menu = {
            id: '', // Will get replaced on publish
            name: this.state.name,
            hours: this.state.hours,
            sections: [],
        };

        if (this.props.itemToEdit) {
            menu.id = this.props.itemToEdit.id;
            menu.sections = [...this.props.itemToEdit.sections];
            edit = true;
        }

        this.props.onClose(menu, edit);
    }

    private cancelMenuCreation = () => {
        this.props.onClose(null, false);
    }

    private handleNameChange = (event: React.FormEvent<EventTarget>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;

        this.setState({
            name: value
        })
    }

    private handleAvailabilityChange = (availability: {open: string, close: string, selected: boolean}[], key: number) => {
        let hours = [...this.state.hours];
        for (let i = 0; i < hours.length; i++) {
            let author: number = hours[i].editedBy;
            let newAvailability = availability[i];
            if (author === key || author === 0) {
                if (newAvailability.selected) {
                    hours[i].editedBy = key;
                    hours[i].open = newAvailability.open;
                    hours[i].close = newAvailability.close;
                } else { // Reset
                    hours[i].editedBy = 0;
                    hours[i].open = '';
                    hours[i].close = '';
                }
            }
        }
        this.setState({hours: hours});
    }

    private addAvailabilityRule = () => {
        this.setState((state) => {
            return {
                numAvailabilityRules: state.numAvailabilityRules + 1,
            }
        });
    }

    private removeAvailabilityRule = () => {
        let numRules: number = this.state.numAvailabilityRules;
        this.setState({
            numAvailabilityRules: numRules - 1,
        });
        
        // Pass in availability change as if it was all deselected
        this.handleAvailabilityChange( [{open: '', close: '', selected: false}, {open: '', close: '', selected: false}, {open: '', close: '', selected: false}, {open: '', close: '', selected: false}, {open: '', close: '', selected: false}, {open: '', close: '', selected: false}, {open: '', close: '', selected: false}], numRules)
    }

    private renderAvailabilityRules = () => {
        let rules: JSX.Element[] = [];
        for (let i = 0; i < this.state.numAvailabilityRules; i++) {
            let currentAuthor: number = i + 1;

            // Check which days are available for editing
            let takenDays: number[] = [];
            for (let j = 0; j < 7; j++) {
                let currentDayAuthor = this.state.hours[j].editedBy;
                if (currentDayAuthor !== 0 && currentDayAuthor !== currentAuthor) {
                    takenDays.push(j);
                }
            }

            // This will get the default values from the state
            let weekdays: boolean[] = [false, false, false, false, false, false, false];
            let from: string = '';
            let to: string = '';
            for (let j = 0; j < 7; j++) {
                let rule = this.state.hours[j];
                if (rule.editedBy === currentAuthor) {
                    weekdays[j] = true;
                    from = rule.open;
                    to = rule.close;
                }
            }

            rules.push(
                <AvailabilityRule
                    key={currentAuthor} 
                    value={{ weekdays, from, to }}
                    author={currentAuthor} 
                    onChange={this.handleAvailabilityChange} 
                    takenDays={takenDays}
            />)
        }
        return rules;
    }

    render() {        
        return (
            <Modal
                isOpen={true}
                contentLabel='Create a Menu'
                overlayClassName='modal-bg'
                className='modal-box panel'
                closeTimeoutMS={150}
                ariaHideApp={false}
            >
                <ReactTooltip effect='solid'/>
                <div className="row-container">
                    <h2 className="section-title">Create Menu</h2>
                    <button onClick={this.cancelMenuCreation} className="button-outline">Cancel</button>
                </div>
                <form onSubmit={this.publishMenu} method="POST" id='' className="form create-form">
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Name</label>
                            <IoIosInformationCircleOutline 
                                data-multiline={true}
                                data-tip='Your food selection can be grouped into various menus.<br />These can be called Breakfast, Lunch, Happy Hour, or even just Menu for your general selection.'
                            />
                        </div>
                        <input required type="text" id="name" name="name" value={this.state.name} onChange={this.handleNameChange}></input>
                    </div>
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Availability</label>
                            <IoIosInformationCircleOutline 
                                data-multiline={true}
                                data-tip="You can restrict your menus to certain hours. For example, your breakfast menu can be restricted to mornings.<br />You can add additional rules if the menu's hours vary throughout days. Leaving the times and days blank signifies all day."
                            />
                        </div>
                        {this.renderAvailabilityRules()}
                        <div id="rule-button-container">
                            <button type='button' className='secondary new-rule' onClick={this.addAvailabilityRule}>
                                <IoIosAdd size={'1.5em'}/>
                                Add New Rule
                            </button>
                            {this.state.numAvailabilityRules > 1 && <button type='button' className='secondary new-rule' onClick={this.removeAvailabilityRule}>
                                <IoIosTrash size={'1.5em'}/>
                                Remove Rule
                            </button>}

                        </div>
                    </div>
                    <button type='submit' className="col-12">{this.props.itemToEdit ? 'Update' : 'Create'}</button>
                </form>
            </Modal>
        );
    }
}

export default CreateMenu;