import React from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { IoIosInformationCircleOutline, IoIosAdd, IoIosTrash } from 'react-icons/io';
import { MenuItem } from '../../../../types/Menu';

interface Props {
    onClose: (truck: MenuItem | null, edit: boolean) => void,
    itemToEdit: MenuItem | null,
};
interface State {
    name: string,
    description: string,
    calories: string,
    price: string,
};

export default class CreateItem extends React.Component<Props, State> {
    readonly state = {
        name: '',
        description: '',
        calories: '',
        price: '',
    };

    componentDidMount() {
        let itemToEdit: MenuItem | null = this.props.itemToEdit;
        if (itemToEdit) {
            let name: string = itemToEdit.name;
            let price: number = itemToEdit.price;
            let desc: string = itemToEdit.desc || '';
            if (itemToEdit.calories) {
                // Had to be done separate due to casting issues
                this.setState({
                    calories: itemToEdit.calories.toString()
                })
            }
            this.setState({
                name,
                price: price.toString(),
                description: desc,
            });

        }
    }

    private cancelItemCreation = () => {
        this.props.onClose(null, false);
    };

    private publishItem = (evt: any) => {
        evt.preventDefault();

        let edit: boolean = false;
        let menuItem: MenuItem = {
            name: this.state.name,
            price: +this.state.price,
            desc: this.state.description,
            calories: +this.state.calories,
            photoURL: '',
            id: Date.now().toString() // probably find a better way of generating unique ids
        }

        if (this.props.itemToEdit) {
            menuItem.photoURL = this.props.itemToEdit.photoURL;
            menuItem.id = this.props.itemToEdit.id;
            edit = true;
        }
        this.props.onClose(menuItem, edit);
    }

    private handleInputChange = (event: React.FormEvent<EventTarget>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value.toString();

        this.setState({
            [target.name]: value
        } as unknown as Pick<State, keyof State>)
    }

    render() {
        return (
            <Modal
                isOpen={true}
                contentLabel='Create an Item'
                overlayClassName='modal-bg'
                className='modal-box panel'
                closeTimeoutMS={150}
                ariaHideApp={false}
            >
                <ReactTooltip effect='solid'/>
                <div className="row-container">
                    <h2 className="section-title">Create Item</h2>
                    <button onClick={this.cancelItemCreation} className="button-outline">Cancel</button>
                </div>
                <form onSubmit={this.publishItem} method="POST" id='' className="form create-form">
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Name</label>
                            <IoIosInformationCircleOutline 
                                data-multiline={true}
                                data-tip='This will be the name of the item.'
                            />
                        </div>
                        <input required type="text" id="name" name="name" value={this.state.name} onChange={this.handleInputChange}></input>
                    </div>
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Description</label>
                            <IoIosInformationCircleOutline
                                data-tip='This field is not necessary, but it may be helpful to provide a short description.'
                            />
                        </div>
                        <input type="text" id="description" name="description" value={this.state.description} onChange={this.handleInputChange}/>
                    </div>
                    <div className='row'>
                        <div className="form-field col">
                            <div className="tooltip-label">
                                <label>Price</label>
                            </div>
                            <input required type="number" step="0.01" id="price" name="price" value={this.state.price} onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-field col">
                            <div className="tooltip-label">
                                <label>Calorie Count</label>
                                <IoIosInformationCircleOutline
                                    data-tip='This field is not necessary, but it may be helpful to provide consumers with a calorie count.'
                                />
                            </div>
                            <input type="number" id="calories" name="calories" value={this.state.calories} onChange={this.handleInputChange}/>
                        </div>
                        
                    </div>
                    <button type='submit' className="col-12">{this.props.itemToEdit ? 'Update' : 'Create'}</button>
                </form>
            </Modal>
        )
    }
}