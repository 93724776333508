import React from 'react';
import firebase, { User } from 'firebase';
import { IoIosAlert, IoIosCheckmark } from 'react-icons/io';

interface Props {

}
interface State {
    error: boolean,
    sent: boolean,
    errorMessage: JSX.Element,
}

class PasswordReset extends React.Component<Props, State> {
    readonly state = {error: false, errorMessage: <p></p>, sent: false};

    sendResetLink = (evt: any) => {
        evt.preventDefault();

        let emailAddress: string = evt.target.email.value;

        console.log(emailAddress);
        if (emailAddress) {
            firebase.auth().sendPasswordResetEmail(emailAddress).then(() => {
                // Email sent.
                // This will display a confirmation box
                this.setState({error: false, sent: true});
            }).catch((error: firebase.auth.Error) => {
                // An error happened.
                console.error(error.code);
                console.error(error.message);

                if (error.code === 'auth/user-not-found') {
                    this.setState({
                        errorMessage: <React.Fragment>This user does not have an account.<a className='error-link' href='/register'> Register here</a></React.Fragment>,
                    })
                } else if (error.code === 'auth/invalid-email') {
                    this.setState({
                        errorMessage: <React.Fragment>This is not a valid email</React.Fragment>,
                    })
                }
                this.setState({error: true});
            });
        }
    }

    render() {
        return (
            <div className='bg' id='verify-bg'>
                <div className='section' id='verify-section'>
                    <h2 className="section-title">Reset your password</h2>
                    <div className="section-content">
                        <p>Enter your email address to reset your password. You may have to check your spam folder.</p>
                        <form onSubmit={this.sendResetLink}>
                            {
                                this.state.error ? 
                                    <div className="alert-bg error-bg"><div>{this.state.errorMessage}</div><IoIosAlert className='alert-icon' /></div> : 
                                    null
                            }
                            {
                                this.state.sent ? 
                                    <div className="alert-bg success-bg"><div>It's sent! Check your inbox.</div><IoIosCheckmark className='alert-icon check-icon'/></div> :
                                    null
                            }
                            <div className="form-field">
                                <label>Email</label>
                                <input required type="text" id="email" name="email"></input>
                            </div>
                            <button className="col-12">Submit</button>                   
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordReset;