import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import "./ImageGallery.css";
import ImageEntry from "./ImageEntry";

interface Props {
    deleteImage: (url: string) => any;
    onTruckLinkSelect: (url: string) => any;
    onMenuLinkSelect: (url: string) => any;
    images: {
        url: string;
        name: string;
        path: string;
        dateUploaded: string;
    }[];
}
interface State {}

export default class ImageGallery extends React.Component<Props, State> {
    readonly state: State = {
        images: [],
    };

    render() {
        return (
            <SimpleReactLightbox>
                <SRLWrapper>
                    <table className="table table-hover gallery-table">
                        <thead>
                            <tr>
                                <th className="gallery-table-header" scope="col">
                                    Thumbnail
                                </th>
                                <th className="gallery-table-header" scope="col">
                                    Name
                                </th>
                                <th className="gallery-table-header" scope="col">
                                    Date Uploaded
                                </th>
                                <th className="gallery-table-header" scope="col">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.images.map((image) => {
                                return (
                                    <ImageEntry
                                        key={image.url}
                                        url={image.url}
                                        name={image.name}
                                        date={image.dateUploaded}
                                        onDelete={this.props.deleteImage}
                                        onTruckLink={this.props.onTruckLinkSelect}
                                        onMenuLink={this.props.onMenuLinkSelect}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </SRLWrapper>
            </SimpleReactLightbox>
        );
    }
}
