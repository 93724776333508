export const convertTo12Hour = (time: string): string => {
    let timePieces = time.split(':');
    let hour: number = +timePieces[0];
    let afternoon: boolean = false;
    if (hour > 12) {
        afternoon = true;
        hour -= 12;
    }
    let period: string = afternoon ? 'PM' : 'AM';

    let showMinutes: boolean = timePieces.length > 1 && timePieces[1] !== '00';

    return `${hour}${showMinutes ? ':' + timePieces[1] : ''}${period}`;
}
export const createTimeRange = (open: string, close: string): string => {
    if (open === '' && close === '') {
        return ''
    } else {
        return `${convertTo12Hour(open)} - ${convertTo12Hour(close)}`;
    }
}
export const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];