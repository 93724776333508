import React from 'react';
import { Menu, Section, MenuItem } from '../../../../../types/Menu';
import { IoIosAdd } from 'react-icons/io';

import './Column.css';
import ColumnEntry from './ColumnEntry/ColumnEntry';

interface Props {
    data: Array<Menu | Section | MenuItem>,
    title: string,
    selectedID: string | null,
    dataExpected?: boolean, // If the prev column was clicked, then show the empty list component if data is empty
    onEntrySelect: (id: string) => void,
    onCreateSelect: (col: 'menu' | 'section' | 'item') => void,
};
interface State {

};

export default class Column extends React.Component<Props, State> {
    static defaultProps = { dataExpected: false };
    readonly state = {};

    private createEntry = (): void => {
        if (this.props.title === 'Menus') {
            this.props.onCreateSelect('menu');
        } else if (this.props.title === 'Sections') {
            this.props.onCreateSelect('section');
        } else if (this.props.title === 'Items') {
            this.props.onCreateSelect('item');
        }
    }

    render() {
        let itemNameSingular: string = 'Menu';
        let itemNamePlural: string = 'menus';
        let parentName: string = '';

        if (this.props.title === 'Sections') {
            itemNameSingular = 'Section';
            itemNamePlural = 'sections';
            parentName = 'menu'
        }
        else if (this.props.title === 'Items') {
            itemNameSingular = 'Item';
            itemNamePlural = 'items';
            parentName = 'section';
        }

        if ((this.props.data.length === 0) && this.props.dataExpected) {
            return (
                <div className="col column">
                    <h2 className="col-title">{this.props.title}</h2>
                    <div className="empty-list-container">
                        <h3 className='empty-list-text'>You don't have any {itemNamePlural} in this {parentName}.</h3>
                        <button onClick={this.createEntry}>Add {itemNameSingular}</button>
                    </div>
                </div>
            )
        }
        if (this.props.data.length === 0) {
            return <div className="col column">
                <h2 className="col-title">{this.props.title}</h2>
                <div className="empty-list-container">
                </div>
            </div>
        }

        return (
            <div className='col column'>
                <h2 className='col-title'>{this.props.title}</h2>
                <button className='entry-add-button column-entry' onClick={this.createEntry}><IoIosAdd size={'1.5em'}/> Add {itemNameSingular}</button>
                {this.props.data.map((item: Menu | Section | MenuItem, index: number) => {
                    let selected: boolean = this.props.selectedID === item.id;
                    return (
                        <ColumnEntry 
                            title={item.name}
                            id={item.id}
                            onSelect={this.props.onEntrySelect}
                            key={index}
                            selected={selected}
                        />
                    )
                })}

            </div>
        )
    }
}