import React from 'react';

import './ColumnEntry.css';

interface Props {
    title: string,
    id: string,
    onSelect: (id: string) => void
    selected: boolean,
};
interface State {

};

export default class ColumnEntry extends React.Component<Props, State> {
    readonly state = {};

    render() {
        return (
            <div className={`column-entry ${this.props.selected && 'active'}`} onClick={() => this.props.onSelect(this.props.id)}>
                <p>{this.props.title}</p>
            </div>
        )
    }
}