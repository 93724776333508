import React from "react";
import MenuIcon from "../../../assets/MenuIcon.svg";
import { IoIosAlert } from "react-icons/io";

import CreateMenu from "./CreateMenu/CreateMenu";
import CreateSection from "./CreateSection/CreateSection";
import CreateItem from "./CreateItem/CreateItem";
import { Menu, Section, MenuItem, determineType } from "../../../types/Menu";
import MenuTable from "./MenuTable/MenuTable";

import {
    getMenus,
    addMenu,
    addSection,
    addMenuItem,
    updateMenu,
    updateSection,
    updateMenuItem,
    deleteMenu,
    deleteSection,
    deleteMenuItem,
} from "../../../api/firestore-menus";
import EntryInfo from "./EntryInfo/EntryInfo";
import ConfirmDeletionModal from "../../../components/ConfirmDeletionModal/ConfirmDeletionModal";

interface Props {
    uid: string;
}
interface State {
    loading: boolean;
    creatingMenu: boolean;
    creatingSection: boolean;
    creatingItem: boolean;
    menus: Menu[];
    selectedEntry: Menu | Section | MenuItem | null;
    editingEntry: Menu | Section | MenuItem | null;

    // Will be used to determine the parent menu for the creation of an item
    selectedMenu: Menu | null;
    selectedSection: Section | null;

    deletingEntry: boolean;
}

class MenuTab extends React.Component<Props, State> {
    readonly state: State = {
        menus: [],
        loading: true,
        creatingMenu: false,
        creatingSection: false,
        creatingItem: false,
        selectedEntry: null,
        editingEntry: null,
        selectedMenu: null,
        selectedSection: null,
        deletingEntry: false,
    };

    componentDidMount() {
        getMenus(this.props.uid)
            .then((data) => {
                this.setState({
                    menus: data,
                    loading: false,
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private openCreateMenu = (): void => {
        this.setState({ creatingMenu: true });
    };

    private openCreateSection = (parentMenu: Menu | null): void => {
        if (parentMenu) {
            this.setState({
                creatingSection: true,
                selectedMenu: parentMenu,
            });
        }
    };

    private openCreateItem = (parentMenu: Menu | null, parentSection: Section | null): void => {
        if (parentMenu && parentSection) {
            this.setState({
                creatingItem: true,
                selectedMenu: parentMenu,
                selectedSection: parentSection,
            });
        }
    };

    private handleMenuModalClose = (menu: Menu | null, edit: boolean) => {
        if (menu) {
            if (edit) {
                updateMenu(this.props.uid, menu)
                    .then((successful) => {
                        if (successful) {
                            // replace menu with new menu visually
                            this.setState((prevState) => {
                                let menus: Menu[] = prevState.menus;
                                menus = menus.map((item: Menu) => {
                                    if (item.id === menu.id) {
                                        // use the new menu
                                        return menu;
                                    }
                                    return item;
                                });

                                // modify the state
                                return {
                                    menus,

                                    // This is to refresh the entry view
                                    // with the new updated value
                                    selectedEntry: menu,
                                };
                            });
                        } else {
                            throw "Result did not update";
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        // display error
                    });
            } else {
                addMenu(this.props.uid, menu)
                    .then((menuID: string) => {
                        if (!menuID) {
                            throw "Result did not save";
                        }

                        menu.id = menuID;
                        this.setState((prevState) => {
                            return { menus: [menu, ...prevState.menus] };
                        });
                    })
                    .catch((err) => {
                        // display visual error
                    });
            }
        }
        this.setState({
            creatingMenu: false,
            editingEntry: null,
        });
    };

    private handleSectionModalClose = (section: Section | null, edit: boolean) => {
        if (section && this.state.selectedMenu) {
            let selectedMenu = { ...this.state.selectedMenu };
            let menuID: string = selectedMenu.id;
            if (edit) {
                updateSection(this.props.uid, menuID, section)
                    .then((successful) => {
                        if (!successful) {
                            throw "Transaction did not run";
                        }
                        // At this point, we can display the changes visually

                        // Replace the edited section with the new section
                        selectedMenu.sections = selectedMenu.sections.map((item: Section) => {
                            if (item.id === section.id) {
                                return section;
                            }
                            return item;
                        });

                        this.setState((prevState: State) => {
                            return {
                                // This replaces the edited menu with the new menu
                                menus: prevState.menus.map((item: Menu) => {
                                    if (item.id === menuID) {
                                        return selectedMenu;
                                    }
                                    return item;
                                }),

                                // This refreshes EntryInfo with the new data
                                selectedEntry: section,
                                selectedSection: section,
                            };
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                // Insert the section into the parent menu
                addSection(this.props.uid, menuID, section).then((successful: boolean) => {
                    if (!successful) {
                        throw "Transaction did not run";
                    }

                    // Display the change visually
                    selectedMenu.sections.push(section);
                    this.setState((prevState) => {
                        return {
                            menus: prevState.menus.map((menu) => {
                                // If the ID is right return the new menu
                                if (menu.id === menuID) {
                                    return selectedMenu;
                                } else {
                                    return menu;
                                }
                            }),
                        };
                    });
                });
            }
        }
        this.setState({
            creatingSection: false,
            editingEntry: null,
        });
    };

    private handleItemModalClose = (item: MenuItem | null, edit: boolean) => {
        if (item && this.state.selectedMenu && this.state.selectedSection) {
            let selectedMenu: Menu = { ...this.state.selectedMenu };
            let selectedSection: Section = { ...this.state.selectedSection };
            let menuID = selectedMenu.id;
            let sectionID = selectedSection.id;

            if (edit) {
                updateMenuItem(this.props.uid, menuID, sectionID, item).then((successful) => {
                    if (!successful) {
                        throw "Transaction did not run";
                    }

                    // We can now display the updated menu item visually
                    selectedMenu.sections = selectedMenu.sections.map((section) => {
                        if (section.id === sectionID) {
                            // Update the section item with a new array
                            section.items = section.items.map((menuItem) => {
                                if (item.id === menuItem.id) {
                                    // Replace with update object
                                    return item;
                                }
                                return menuItem;
                            });
                        }
                        return section;
                    });

                    // Now the state change happens
                    this.setState((prevState: State) => {
                        let menus: Menu[] = [...prevState.menus];
                        menus.map((menu) => {
                            if (menu.id === menuID) {
                                // return the new menu
                                return selectedMenu;
                            }
                            return menu;
                        });

                        // Finally, set the state
                        return {
                            menus,

                            // This refreshes EntryInfo with the new data
                            selectedEntry: item,
                        };
                    });
                });
            } else {
                addMenuItem(this.props.uid, menuID, sectionID, item)
                    .then((successful) => {
                        if (!successful) {
                            throw "Transaction did not run";
                        }

                        // Modify the selected menu with the updated fields
                        selectedMenu.sections = selectedMenu.sections.map((section) => {
                            if (section.id === sectionID) {
                                // Append the items array with the new item
                                section.items.push(item);
                            }
                            return section;
                        });

                        this.setState((prevState: State) => {
                            // Now modify the menus array with the new menu
                            // I think the clone is neccessary since prevState
                            // is readonly
                            let menus: Menu[] = [...prevState.menus];
                            menus.map((menu) => {
                                if (menu.id === menuID) {
                                    // return the new menu
                                    return selectedMenu;
                                }
                                return menu;
                            });

                            // Finally, set the state
                            return {
                                menus,
                            };
                        });
                    })
                    .catch(() => {
                        // display error visually
                    });
            }
        }
        this.setState({
            creatingItem: false,
            editingEntry: null,
        });
    };

    private isEmptyList = (): boolean => {
        return this.state.menus.length === 0;
    };

    private getEmptyListComponent = (): JSX.Element => {
        if (this.state.loading) {
            return <div></div>;
        } else {
            return (
                <div
                    className="container d-flex flex-column align-items-center"
                    id="empty-list-container"
                >
                    <div className="circle-icon">
                        <img src={MenuIcon} alt="No menus found" />
                    </div>
                    <h4>Oops! You don't seem to have any menus.</h4>
                    <button
                        onClick={() => {
                            this.openCreateMenu();
                        }}
                    >
                        Create a Menu
                    </button>
                </div>
            );
        }
    };

    private setSelectedEntry = (entry: Menu | Section | MenuItem): void => {
        // This is so that when you open the edit menu, the parent section/menu is known
        // The edit action comes from the EntryInfo component, so the parent has to be
        // set during the initial selection as EntryInfo doesn't know the hierarchy
        // of the data
        let type: string = determineType(entry);
        if (type === "menu") {
            this.setState({ selectedMenu: entry as Menu });
        } else if (type === "section") {
            this.setState({ selectedSection: entry as Section });
        }

        // This is for EntryInfo
        this.setState({ selectedEntry: entry });
    };

    private handleItemEdit = (entry: Menu | Section | MenuItem): void => {
        let type: string = determineType(entry);
        if (type === "menu") {
            this.setState({
                creatingMenu: true,
                editingEntry: entry,
            });
        } else if (type === "section") {
            this.setState({
                creatingSection: true,
                editingEntry: entry,
            });
        } else if (type === "menu item") {
            this.setState({
                creatingItem: true,
                editingEntry: entry,
            });
        } else {
            // how did it even get here?
        }
    };

    private determineDeletionModalTitle = (): string => {
        let title: string = "";

        if (this.state.selectedEntry) {
            title = `Delete ${this.state.selectedEntry.name}`;
        }
        return title;
    };

    private determineDeletionModalText = (): string => {
        let deletionText: string = "";
        if (this.state.selectedEntry) {
            let type: string = determineType(this.state.selectedEntry);
            let subsequentText: string = "";
            if (type === "menu" || type === "section") {
                let childrenItems: string = type === "menu" ? "sections" : "items";
                subsequentText = ` and any ${childrenItems} that are in it`;
            }
            deletionText = `This will permanently delete the ${type} ${this.state.selectedEntry.name}${subsequentText}. Are you sure?`;
        }

        return deletionText;
    };

    private handleItemDelete = () => {
        const itemToDelete = this.state.selectedEntry;
        if (itemToDelete) {
            let type: string = determineType(itemToDelete);
            if (type === "menu") {
                deleteMenu(this.props.uid, itemToDelete.id)
                    .then((successful) => {
                        if (!successful) {
                            throw "Menu deletion not successful";
                        }

                        // Remove menu from view
                        this.setState((prevState: State) => {
                            let menus = [...prevState.menus];
                            menus = menus.filter((menu) => menu.id !== itemToDelete.id);

                            return {
                                menus,
                                deletingEntry: false,

                                // Deselect everything
                                selectedEntry: null,
                                selectedMenu: null,
                                selectedSection: null,
                            };
                        });
                    })
                    .catch((err) => {
                        // Display failure to delete visually
                        console.error(err);
                    });
            } else if (type === "section") {
                if (this.state.selectedMenu) {
                    let menuID: string = this.state.selectedMenu.id;
                    deleteSection(this.props.uid, menuID, itemToDelete.id)
                        .then((successful) => {
                            if (!successful) {
                                throw "Section did not delete";
                            }
                            // Remove section from sections array in selected menu
                            this.setState((prevState: State) => {
                                let menus = [...prevState.menus];
                                let newSelectedMenu: Menu | null = null;

                                // Remove the section
                                menus = menus.map((menu: Menu) => {
                                    if (menu.id === menuID) {
                                        newSelectedMenu = menu;
                                        menu.sections = menu.sections.filter((section) => {
                                            return section.id !== itemToDelete.id;
                                        });
                                    }
                                    return menu;
                                });

                                return {
                                    menus,
                                    selectedSection: null,
                                    deletingEntry: false,

                                    // When you delete a section, the parent menu
                                    // should be selected
                                    selectedEntry: newSelectedMenu,
                                };
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            } else if (type === "menu item") {
                if (this.state.selectedMenu && this.state.selectedSection) {
                    let menuID: string = this.state.selectedMenu.id;
                    let sectionID: string = this.state.selectedSection.id;
                    deleteMenuItem(this.props.uid, menuID, sectionID, itemToDelete.id)
                        .then((successful) => {
                            if (!successful) {
                                throw "Menu Item did not delete";
                            }

                            // Remove the item from items array from section in sections array
                            this.setState((prevState: State) => {
                                let menus = [...prevState.menus];
                                let newSelectedSection: Section | null = null;

                                // Remove the section
                                menus = menus.map((menu: Menu) => {
                                    if (menu.id === menuID) {
                                        menu.sections = menu.sections.map((section) => {
                                            if (section.id === sectionID) {
                                                newSelectedSection = section;
                                                section.items = section.items.filter(
                                                    (item) => item.id !== itemToDelete.id
                                                );
                                            }
                                            return section;
                                        });
                                    }
                                    return menu;
                                });
                                return {
                                    menus,
                                    deletingEntry: false,

                                    // When you delete an item, the parent section
                                    // should be selected
                                    selectedEntry: newSelectedSection,
                                };
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            }
        }
    };

    private closeDeletionModal = () => {
        this.setState({
            deletingEntry: false,
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.isEmptyList() || (
                    <div className="panel">
                        <p className="text-high" style={{ marginBottom: 0 }}>
                            <IoIosAlert size={30} style={{ marginTop: -3, marginRight: 5 }} />
                            To link a menu to a truck, head to the trucks tab, edit the food truck
                            and select the menu that you want to link.
                        </p>
                    </div>
                )}
                <div className={`panel ${!this.isEmptyList() && "fit"}`}>
                    {this.state.creatingMenu && (
                        <CreateMenu
                            onClose={this.handleMenuModalClose}
                            itemToEdit={this.state.editingEntry as Menu}
                        />
                    )}
                    {this.state.creatingSection && (
                        <CreateSection
                            onClose={this.handleSectionModalClose}
                            itemToEdit={this.state.editingEntry as Section}
                        />
                    )}
                    {this.state.creatingItem && (
                        <CreateItem
                            onClose={this.handleItemModalClose}
                            itemToEdit={this.state.editingEntry as MenuItem}
                        />
                    )}
                    {this.isEmptyList() ? (
                        <React.Fragment>
                            <div className="row-container">
                                <h2 className="section-title">Menus</h2>
                                {this.isEmptyList() || (
                                    <button
                                        onClick={this.openCreateMenu}
                                        className="button-outline"
                                    >
                                        Create
                                    </button>
                                )}
                            </div>
                            {this.getEmptyListComponent()}
                        </React.Fragment>
                    ) : (
                        <MenuTable
                            menus={this.state.menus}
                            createMenu={this.openCreateMenu}
                            createSection={this.openCreateSection}
                            createItem={this.openCreateItem}
                            onEntrySelect={this.setSelectedEntry}
                        />
                    )}
                </div>
                {this.state.selectedEntry ? (
                    <EntryInfo
                        entry={this.state.selectedEntry}
                        onEdit={this.handleItemEdit}
                        onDelete={() => {
                            this.setState({ deletingEntry: true });
                        }}
                    />
                ) : null}
                <ConfirmDeletionModal
                    isOpen={this.state.deletingEntry}
                    title={this.determineDeletionModalTitle()}
                    extraText={this.determineDeletionModalText()}
                    onConfirm={this.handleItemDelete}
                    onClose={this.closeDeletionModal}
                />
            </React.Fragment>
        );
    }
}

export default MenuTab;
