export type FoodTruck = {
    truckID: string;
    name: string;
    desc: string;
    phoneNumber: string;
    coordinates: { latitude: number; longitude: number };
    street: string;
    city: string;
    state: string;
    postalCode: string;
    thumbnailUrl: string;
    bannerUrl: string;
    foodCategories: string[];
    hours: { open: string; close: string }[];
    deployed: boolean;
    dateCreated: Date;
    menus: string[]; // list of ids
};

export type FoodTruckDisplay = {
    name: string;
    desc: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    foodCategories: string[];
    dateCreated: Date;
    deployed: boolean;
};

export const convertTruckToDisplay = (truck: FoodTruck): FoodTruckDisplay => {
    return {
        ...truck,
    };
};

export const convertTruckToData = (truck: FoodTruckDisplay): FoodTruck => {
    return {
        ...truck,
        truckID: "",
        phoneNumber: "",
        bannerUrl: "",
        thumbnailUrl: "",
        coordinates: { latitude: 0, longitude: 0 },
        foodCategories: [],
        hours: [{ open: "", close: "" }],
        menus: [],
    };
};

export const FoodCategory: string[] = [
    "Asian",
    "BBQ",
    "Breakfast",
    "Burgers/Sandwiches",
    "Caribbean",
    "Central American",
    "Coffee",
    "Desserts",
    "Gluten Free",
    "Indian",
    "Italian",
    "Mediterranean",
    "Middle Eastern",
    "Seafood",
    "South American",
    "Vegetarian",
    "Vegan",
];
