import React, { SyntheticEvent } from 'react';
import { IoIosAlert } from "react-icons/io";
import firebase from 'firebase';
import { History } from 'history';

import './Login.css';

interface Props {
    history: History,
};

interface State {
    error: boolean,
    errorMessage: string,
};

class Login extends React.Component<Props, State> {
    readonly state = { error: false, errorMessage: '' };
    
    private handleLogin = (evt: any) => {
        evt.preventDefault();

        // validate input
        let email: string = evt.target.email.value;
        let password: string = evt.target.password.value;

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                this.props.history.push('/dashboard');
            })
            .catch((error: firebase.auth.Error) => {
                var errorCode: string = error.code;
                var errorMessage: string = error.message;
                console.error(errorCode);
                console.error(errorMessage);
                            
                // Possible errors:
                // auth/user-not-found
                // auth/wrong-password
                // auth/invalid-email
                // auth/user-disabled

                if (errorCode === 'auth/user-not-found') {
                    this.setState({errorMessage: 'This email is not associated with any user.'});
                } else if (errorCode === 'auth/wrong-password') {
                    this.setState({errorMessage: 'Your password is incorrect.'});
                } else if (errorCode === 'auth/invalid-email') {
                    this.setState({errorMessage: 'This is not a valid email.'})
                } else if (errorCode === 'auth/user-disabled') {
                    this.setState({errorMessage: 'This user is disabled.'})
                }

                this.setState({error: true});
            });
    }

    render() {
        return (
            <div className='bg'>
                <div className='section'>
                    <h2 className="section-title">Welcome back</h2>
                    <div className="section-content login-form">
                        <form onSubmit={this.handleLogin} method="POST" className="form">
                            {
                                this.state.error ? 
                                    <div className="alert-bg error-bg"><div>{this.state.errorMessage}</div><IoIosAlert className='alert-icon' /></div> : 
                                    null
                            }
                            <div className="form-field">
                                <label htmlFor='email'>Email</label>
                                <input required className={this.state.error ? 'is-invalid' : ''} type="text" id="email" name="email"></input>
                            </div>
                            <div className="form-field">
                                <label htmlFor='password'>Password</label>
                                <input required className={this.state.error ? 'is-invalid' : ''} type="password" id="password" name="password"></input>
                            </div>
                            <button className="col-12">Log in</button>                   
                        </form>
                        <p className="panel-text text-center">Don't have an account? <a className='link' href="/register">Register.</a></p>
                        <p className="panel-text text-center">Forgot your password? <a className='link' href="/reset">Reset password.</a></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;