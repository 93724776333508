import React from 'react';
import Modal from 'react-modal';
import { IoIosArrowDown, IoIosAlert } from 'react-icons/io';
import { MAPQUEST_KEY } from '../../../key';

import './UserLocationInput.css';
import { getCoordsFromZip, getCityNameFromCoords } from '../../../api/geoencoding';

interface Props {
    onLocationChange: (latitude: number, longitude: number) => any,
};
interface State {
    openModal: boolean,
    zip: string,
    cityName: string,
    error: boolean,
    errorMessage: string,
};

export default class UserLocationInput extends React.Component<Props, State> {
    readonly state: State = {
        openModal: false,
        zip: '',
        cityName: '',
        error: false,
        errorMessage: '',
    };

    componentDidMount() {
        if (this.hasStorage()) {
            let zip: string | null = localStorage.getItem('zip');
            if (zip) {
                this.setState({zip});
                this.setLocationFromZip(zip);
                return;
            }
        }
        this.setState({openModal: true,})
    }

    // Easy check if the browser supports local storage
    private hasStorage = () => {
        try {
            localStorage.setItem('_storagetest_', '_storagetest_');
            localStorage.removeItem('_storagetest_');
            return true;
        } catch (exception) {
            return false;
        }
    };

    private toggleModal = () => {
        this.setState((prevState: State) => {
            return {
                openModal: !prevState.openModal,
                error: false,
            }
        })
    }

    private onZipCodeChange = (evt: any) => {
        let zip: number = evt.target.value;

        // Set to local storage
        if (this.hasStorage()) {
            localStorage.setItem('zip', zip.toString());
        }

        this.setState({
            zip: zip.toString(),
        });
    }

    private setLocationFromZip = (zip: string) => {
        this.setState({error: false});
        // find the latitude and longitude of the zip code
        getCoordsFromZip(+zip)
            .then((data) => {
                let latitude = data.coordinates.lat;
                let longitude = data.coordinates.lng;
                this.props.onLocationChange(latitude, longitude);
                this.setState({
                    cityName: data.city,
                    openModal: false,
                })
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    error: true,
                    errorMessage: 'Something went wrong fetching trucks in your area. Please try again soon.'
                })
            });
    }

    private onFormSubmit = (evt: any) => {
        evt.preventDefault();

        this.setLocationFromZip(this.state.zip);       
    }
 
    private requestGPSLocation = () => {
        if (!navigator.geolocation) {
            alert("Geolocation is not available on this browser.");
            return;
        }
        navigator.geolocation.getCurrentPosition(this.setLocation, this.handleLocationError)
    }
    
    private setLocation = (position: {coords: {latitude: number, longitude: number}}) => {
        let { latitude, longitude } = position.coords;
        
        getCityNameFromCoords(latitude, longitude)
            .then((cityName) => {
                this.setState({
                    cityName,
                })
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.setState(() => {
                    this.props.onLocationChange(latitude, longitude);
                    return {
                        openModal: false,
                        error: false,
                    }
                })
            })
    }
    
    private handleLocationError = (err: any) => {
        this.setState({
            error: true,
            errorMessage: 'Something went wrong... Please try a ZIP code.'
        })
        console.error(err);
    }

    render() {
        return (
            <div id="user-location-input-container">
                <button
                    onClick={this.toggleModal}
                    className="secondary"
                    id="user-location-button"
                >
                    {this.state.cityName || 'Location'}
                    <IoIosArrowDown id="down-arrow-icon"/>
                </button>
                <Modal
                    isOpen={this.state.openModal}
                    contentLabel="Change Location"
                    overlayClassName="modal-bg"
                    className="modal-box panel panel-small"
                    closeTimeoutMS={150}
                    ariaHideApp={false}
                    htmlOpenClassName='ReactModal__Html--open'
                >
                    <div className="row-container">
                        <h1 className="section-title">Location</h1>
                        <button onClick={this.toggleModal} className='button-outline'>Close</button>
                    </div>
                    <div id="user-location-form">
                        {
                            this.state.error && (
                                <div className="alert-bg error-bg">
                                    <div>
                                        {this.state.errorMessage}  
                                    </div>
                                    <IoIosAlert className='alert-icon' />
                                </div>
                            )
                        }
                        <form onSubmit={this.onFormSubmit}>
                            <div id="zip-container">
                                <input
                                    type="text"
                                    aria-label="ZIP Code"
                                    placeholder="Enter a ZIP Code"
                                    id="user-location-input"
                                    onChange={this.onZipCodeChange}
                                    value={this.state.zip}
                                    required
                                />
                                <button>Search</button>
                            </div>
                        </form>
                        <button
                            type="button"
                            id="user-location-gps-button"
                            className="secondary"
                            onClick={this.requestGPSLocation}
                        >
                            Find Near Me
                        </button>
                    </div>
                </Modal>
            </div>
        )
    }
}