import React from "react";
import Navbar from "../../components/Navbar/Navbar";

import AboutPicture from "../../assets/about.jpg";

import "./About.css";
import Footer from "../../components/Footer/Footer";

export default class About extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Navbar active="about" />
                <div className="about-container">
                    <div className="about-top-section"></div>
                    <div className="about-bottom-section">
                        <div className="about-content">
                            <img src={AboutPicture} alt="The PlateChasr team" />
                            <h1 className="about-title">Our Story</h1>
                            <p className="about-text">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tucked away
                                throughout the corners of our city lie hundreds of hidden gems.
                                Pockets of people and food, who want to kiss your cheeks and keep
                                you warm despite the Miami sun leaving you both sticky. Sometimes,
                                the easiest way for us to feel at home was to get lost searching for
                                our local food trucks. To hunger through the city streets late at
                                night, eager to laugh with local drivers, to collapse together into
                                their plastic tables, to dig into the meal passed from one community
                                member to another. We found the heart of our city and culture while
                                hunting for those perfect bites. And with PlateChasr, we can finally
                                share that journey with the city we love.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
