import firebase from "firebase";

// file for operations related to uploading and downloading to firebase storage

export const uploadImage = (
    image: File,
    uid: string,
    onError: () => any,
    onSuccess: (imageData: any) => any
) => {
    const storageRef = firebase.storage().ref();
    const metadata = {
        contentType: "image/jpeg",
    };
    let uploadTask = storageRef.child(uid + "/" + image.name).put(image, metadata);

    uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
            console.error(error);
            onError();
        },
        () => {
            // upload completed
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: string) => {
                uploadTask.snapshot.ref.getMetadata().then((metadata) => {
                    let imageData = {
                        url: downloadURL,
                        name: uploadTask.snapshot.ref.name,
                        path: uploadTask.snapshot.ref.fullPath,
                        dateUploaded: metadata.dateCreated,
                    };
                    onSuccess(imageData);
                });
            });
        }
    );
};

export const getImageDataFromURL = async (
    url: string
): Promise<{
    url: string;
    name: string;
    path: string;
    dateUploaded: string;
}> => {
    const imageRef = firebase.storage().refFromURL(url);
    const metadata = await imageRef.getMetadata();

    return {
        url,
        name: imageRef.name,
        path: imageRef.fullPath,
        dateUploaded: metadata.timeCreated,
    };
};

export const deleteImage = (url: string, uid: string): Promise<any> => {
    const imageRef = firebase.storage().refFromURL(url);
    let parentFolder = imageRef.parent;
    if (parentFolder?.name === uid) {
        return imageRef.delete();
    } else {
        return Promise.reject("Access denied");
    }
};
