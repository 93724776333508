import React from "react";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FoodCategory, FoodTruck } from "../../../../types/FoodTruck";

import "./CreateFoodTruck.css";
import { getMenus } from "../../../../api/firestore-menus";
import { Menu } from "../../../../types/Menu";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { getCoordsFromAddress } from "../../../../api/geoencoding";
import WeeklyTimePicker from "../../../../components/WeeklyTimePicker/WeeklyTimePicker";

interface Props {
    itemToEdit: FoodTruck | null;
    onClose: (truck: FoodTruck | null, edit: boolean) => void;
    uid: string;
}
interface State {
    foodCategories: string[];
    name: string;
    desc: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    menus: string[];
    hours: { open: string; close: string }[];
    menuObjects: Menu[];
    loadingMenus: boolean;
    deployed: boolean;
    loadingButton: boolean;
}

class CreateFoodTruck extends React.Component<Props, State> {
    readonly state: State = {
        foodCategories: [],
        name: "",
        desc: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        menus: [],
        menuObjects: [],
        hours: [
            { open: "", close: "" },
            { open: "", close: "" },
            { open: "", close: "" },
            { open: "", close: "" },
            { open: "", close: "" },
            { open: "", close: "" },
            { open: "", close: "" },
        ],
        deployed: false,
        loadingMenus: true,
        loadingButton: false,
    };

    componentDidMount() {
        let itemToEdit: FoodTruck | null = this.props.itemToEdit;
        if (itemToEdit) {
            let {
                foodCategories,
                name,
                desc,
                street,
                city,
                state,
                postalCode,
                menus,
                deployed,
                hours,
            } = itemToEdit;

            if (hours.length === 0) {
                hours = this.state.hours;
            }
            // I tried the rest operator but it wouldn't allow it
            this.setState({
                foodCategories,
                name,
                desc,
                street,
                city,
                state,
                postalCode,
                menus,
                deployed,
                hours,
            });
        }

        getMenus(this.props.uid).then((menus: Menu[]) => {
            this.setState({
                menuObjects: menus,
                loadingMenus: false,
            });
        });
    }

    private publishTruck = (evt: any) => {
        evt.preventDefault();

        this.setState({ loadingButton: true });
        // perform a lookup of the address to acquire a zip code
        getCoordsFromAddress(
            this.state.street,
            this.state.city,
            this.state.state,
            this.state.postalCode
        )
            .then((coords) => {
                this.setState({ loadingButton: false });
                console.log(coords);

                let {
                    menuObjects,
                    loadingMenus,
                    loadingButton,
                    menus,
                    ...editedValues
                } = this.state;
                if (this.props.itemToEdit) {
                    // We are removing two fields from the state which shouldnt go to
                    // firestore, menuObjects and loadingMenus

                    // Take this opportunity to remove any menuPaths that aren't in
                    // menuObjects to reduce document size
                    menus = menus.filter((menuPath: string) => {
                        let idTokens: string[] = menuPath.split("/");
                        if (idTokens[1]) {
                            let menuID: string = idTokens[1];
                            // return true if menuObject has that menu id
                            return menuObjects.some((menu) => menu.id === menuID);
                        } else {
                            // The menu path is formatted incorrectly
                            return false;
                        }
                    });

                    // update the entered data to the truck
                    // with the rest operator, the state should overwrite as its last
                    let truck: FoodTruck = {
                        ...this.props.itemToEdit,
                        menus,
                        ...editedValues,
                        coordinates: { latitude: coords.lat, longitude: coords.lng },
                    };
                    this.props.onClose(truck, true);
                } else {
                    let truck: FoodTruck = {
                        ...editedValues,
                        dateCreated: new Date(),
                        menus,
                        coordinates: { latitude: coords.lat, longitude: coords.lng },

                        // values that will be eventually changed
                        phoneNumber: "",
                        truckID: "",
                        thumbnailUrl: "",
                        bannerUrl: "",
                    };
                    this.props.onClose(truck, false);
                }
            })
            .catch((err) => {
                console.error(err);
                this.setState({ loadingButton: false });
            });
    };

    private cancelTruckCreation = () => {
        this.props.onClose(null, false);
    };

    private handleCategoryClick = (category: string) => {
        if (this.state.foodCategories.includes(category)) {
            this.setState({
                foodCategories: this.state.foodCategories.filter((cat) => cat !== category),
            });
        } else {
            this.setState({
                foodCategories: [...this.state.foodCategories, category],
            });
        }
    };

    private handleMenuClick = (menuPath: string) => {
        if (this.state.menus.includes(menuPath)) {
            this.setState((prevState: State) => {
                return {
                    menus: prevState.menus.filter((menu) => menu !== menuPath),
                };
            });
        } else {
            this.setState((prevState: State) => {
                return {
                    menus: [...prevState.menus, menuPath],
                };
            });
        }
    };

    private handleInputChange = (event: React.FormEvent<EventTarget>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        if (name === "deployed") {
            this.setState({
                deployed: target.checked,
            });
        } else if (name !== "foodCategories") {
            this.setState(({
                [name]: value,
            } as unknown) as Pick<State, keyof State>);
        }
    };

    private renderFoodCategories = () => {
        let foodCategories: string[] = Object.keys(FoodCategory)
            .map((k) => FoodCategory[k as any])
            .filter((cat) => isNaN(+cat));

        return foodCategories.map((category, index) => {
            let categoryClass: string = `tag ${
                this.state.foodCategories.includes(category) && "active"
            }`;
            return (
                <button
                    key={index}
                    type="button"
                    className={categoryClass}
                    onClick={() => this.handleCategoryClick(category)}
                >
                    {category}
                </button>
            );
        });
    };

    private renderMenus = () => {
        if (this.state.loadingMenus) {
            return <Spinner />;
        }

        if (this.state.menuObjects.length === 0) {
            return (
                <p className="text-high">
                    You currently have no menus; however, you can create some later in the Menu tab!
                </p>
            );
        }

        return this.state.menuObjects.map((menu, index) => {
            let menuPath: string = `${this.props.uid}/${menu.id}`;
            let menuClass: string = `tag ${this.state.menus.includes(menuPath) && "active"}`;
            return (
                <button
                    key={index}
                    className={menuClass}
                    type="button"
                    onClick={() => this.handleMenuClick(menuPath)}
                >
                    {menu.name}
                </button>
            );
        });
    };

    render() {
        let buttonText: string;
        if (this.state.loadingButton && this.props.itemToEdit) {
            buttonText = "Updating...";
        } else if (this.state.loadingButton && !this.props.itemToEdit) {
            buttonText = "Creating...";
        } else if (!this.state.loadingButton && this.props.itemToEdit) {
            buttonText = "Update";
        } else {
            buttonText = "Create";
        }
        return (
            <Modal
                isOpen={true}
                contentLabel="Create a Food Truck"
                overlayClassName="modal-bg"
                className="modal-box panel"
                closeTimeoutMS={150}
                ariaHideApp={false}
                htmlOpenClassName="ReactModal__Html--open"
            >
                <ReactTooltip effect="solid" />
                <div className="row-container">
                    <h2 className="section-title">Create Food Truck</h2>
                    <button onClick={this.cancelTruckCreation} className="button-outline">
                        Cancel
                    </button>
                </div>
                <form onSubmit={this.publishTruck} method="POST" id="" className="form create-form">
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Truck Name</label>
                            <IoIosInformationCircleOutline data-tip="This will be shown to users in the app as the name of your food truck." />
                        </div>
                        <input
                            required
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                        ></input>
                    </div>
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Description</label>
                            <IoIosInformationCircleOutline data-tip="This will help users understand more about your truck. Make it brief and engaging." />
                        </div>
                        <input
                            required
                            type="text"
                            id="description"
                            name="desc"
                            value={this.state.desc}
                            onChange={this.handleInputChange}
                        ></input>
                    </div>
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Food Categories</label>
                            <IoIosInformationCircleOutline data-tip="This will tell users what type of food to expect at your truck. Be accurate or you risk confusing your customers." />
                        </div>
                        <p>Please select at least one.</p>
                        <div className="category-container">{this.renderFoodCategories()}</div>
                    </div>
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Menus</label>
                            <IoIosInformationCircleOutline data-tip="You can link any amount of menus that you have created to this food truck. This can be edited at any times." />
                        </div>
                        <div className="category-container">{this.renderMenus()}</div>
                    </div>
                    <hr></hr>
                    <p>Truck Address</p>
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Street Address</label>
                            <IoIosInformationCircleOutline data-tip="This will be where users can primarily find your truck." />
                        </div>
                        <input
                            required
                            type="text"
                            id="street-address"
                            name="street"
                            value={this.state.street}
                            onChange={this.handleInputChange}
                        ></input>
                    </div>
                    <div className="row">
                        <div className="form-field col-md-4 col-xs-12">
                            <div className="tooltip-label">
                                <label>City</label>
                            </div>
                            <input
                                required
                                type="text"
                                id="city"
                                name="city"
                                value={this.state.city}
                                onChange={this.handleInputChange}
                            ></input>
                        </div>
                        <div className="form-field col-md-4 col-xs-12">
                            <div className="tooltip-label">
                                <label>State</label>
                            </div>
                            <input
                                required
                                type="text"
                                id="state"
                                name="state"
                                value={this.state.state}
                                onChange={this.handleInputChange}
                            ></input>
                        </div>
                        <div className="form-field col-md-4 col-xs-12">
                            <div className="tooltip-label">
                                <label>ZIP Code</label>
                            </div>
                            <input
                                required
                                type="number"
                                id="zip-code"
                                name="postalCode"
                                value={this.state.postalCode}
                                onChange={this.handleInputChange}
                            ></input>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="form-field">
                        <div className="tooltip-label" style={{ marginBottom: 10 }}>
                            <label>Hours of Operation</label>
                            <IoIosInformationCircleOutline data-tip="This will help users understand when your truck is open." />
                        </div>
                        <WeeklyTimePicker
                            onChange={(hours) => this.setState({ hours })}
                            value={this.state.hours}
                        />
                    </div>
                    {this.props.itemToEdit !== null || (
                        <div className="form-field row-container">
                            <div className="tooltip-label">
                                <label htmlFor="deploy-automatically">
                                    <input
                                        type="checkbox"
                                        id="deploy-automatically"
                                        name="deployed"
                                        checked={this.state.deployed}
                                        onChange={this.handleInputChange}
                                        style={{ marginRight: 10 }}
                                    ></input>
                                    Go live on the app automatically
                                </label>
                                <IoIosInformationCircleOutline data-tip="If you check this option, the truck will go live automatically when you click create." />
                            </div>
                        </div>
                    )}
                    <button disabled={this.state.loadingButton} type="submit" className="col-12">
                        {buttonText}
                    </button>
                </form>
            </Modal>
        );
    }
}

export default CreateFoodTruck;
