import React from 'react';
import './CardContainer.css';
import { FoodTruck } from '../../../types/FoodTruck';
import TruckCard from '../TruckCard/TruckCard';

interface Props {
    trucks: {truck: FoodTruck, distance: number}[],
    distance: number,
    searchTerm: string,
    categories: string[],
    loading: boolean,
};
interface State {

}

export default class CardContainer extends React.Component<Props, State> {
    readonly state = {};

    private getFilteredFoodTrucks = (trucks: any[]): {truck: FoodTruck, distance: number}[] => {
        trucks = trucks.filter((truck) => {
            if (truck.distance > this.props.distance) {
                return false;
            }
            // Check search terms
            // Improve this to perhaps include fuzzy search
            if (this.props.searchTerm.length > 0) {
                // Normalize by removing uppercase characters and removing punctuation
                let truckDesc: string = truck.truck.desc.toLowerCase();
                let truckName: string = truck.truck.name.toLowerCase();
                let search: string = this.props.searchTerm.toLowerCase();

                if (!truckDesc.includes(search) && !truckName.includes(search)) {
                    return false;
                }
            }

            // Check food categories
            if (this.props.categories.length > 0) {
                // Compute the intersection between the two category arrays
                let intersection = this.props.categories.filter(x => truck.truck.foodCategories.includes(x));

                // If the intersection is empty, then there is no matching
                if (intersection.length === 0) {
                    return false;
                }
            }

            return true;
        })

        // Check if list is empty
        return trucks;
    }

    private getSkeletonCards = () => {
        let cards = [];
        for (let index = 0; index < 6; index++) {
            cards.push(
                <div key={index} className="trucks-card">
                    <div className="truck-card-banner"></div>
                </div>
            )
        }
        return cards;
    }

    render() {
        let truckCards: JSX.Element[];
        if (this.props.loading) {
            truckCards = this.getSkeletonCards();
        } else {
            if (this.props.trucks.length === 0) {
                // No trucks in the area
                return (
                    <div className="empty-list panel">
                        <div className="circle-icon">
                            <img src={require('../../../assets/FoodTruckIcon.svg')} alt="No trucks"/>
                        </div>
                        <h4>No food trucks were found in your area. Please check by later!</h4>
                    </div>
                )
            }
            truckCards = this.getFilteredFoodTrucks(this.props.trucks).map((truck) => {
                    return (
                        <TruckCard
                            truck={truck.truck}
                            distance={truck.distance}
                            key={truck.truck.truckID} //lol
                        />
                    )
                }
            )
        }
        // If no trucks were found with the filters
        if (truckCards.length === 0) {
            return (
                <div className="empty-list panel">
                    <div className="circle-icon">
                        <img src={require('../../../assets/FoodTruckIcon.svg')} alt="No trucks"/>
                    </div>
                    <h4>No trucks were found matching your criteria. Please try broadening your search.</h4>
                </div>
            )
        } else {
            return (
                <div id="cards">
                    {truckCards}
                </div>
            )
        }
    }
}