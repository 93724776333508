import React from 'react';
import './AvailabilityRule.css';

interface Props {
    onChange: (availability: {open: string, close: string, selected: boolean}[], key: number) => void;
    value: { weekdays: boolean[], from: string, to: string },
    author: number;
    takenDays: number[];
};
interface State {
    weekdays: boolean[];
    from: string;
    to: string;
};

export default class AvailabilityRule extends React.Component<Props, State> {
    readonly state = {
        weekdays: [false, false, false, false, false, false, false],
        from: '',
        to: '',
    };

    componentDidMount() {
        this.setState({
            ...this.props.value
        })
    }

    private handleInputChange = () => {
        let availability: {open: string, close: string, selected: boolean}[] = [];
        for (let i = 0; i < 7; i++) {
            availability.push({open: '', close: '', selected: false});
        }

        this.state.weekdays.forEach((day, index) => {
            if (day) {
                availability[index].open = this.state.from;
                availability[index].close = this.state.to;
                availability[index].selected = true;
            }
        });
        this.props.onChange(availability, this.props.author)
    }

    private handleWeekdayClick = (day: number) => {
        let weekdaysCopy: boolean[] = [...this.state.weekdays];
        weekdaysCopy[day] = !weekdaysCopy[day];
        this.setState({weekdays: weekdaysCopy}, this.handleInputChange);
        
    }

    private handleHourChange = (point: string, hour: string) => {
        if (point === 'to'|| point === 'from') {
            this.setState({
                [point]: hour
            } as unknown as Pick<State, keyof State>, this.handleInputChange)
        }

    }

    private renderWeekdays = (): JSX.Element[] => {
        let weekdays: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        return weekdays.map((day, index) => {
            let dayClass: string = `tag fix ${this.state.weekdays[index] && 'active'}`;
            return (
                <button
                    disabled={this.props.takenDays.includes(index)}
                    key={index} 
                    type='button' 
                    className={dayClass} 
                    onClick={() => this.handleWeekdayClick(index)}
                >
                    {day}
                </button>
            )
        })
    }

    private renderHourSelector = (): JSX.Element => {
        return (
            <div className='hour-input-wrapper'>
                <label className='form-field'>
                    From
                    <input 
                        aria-label='From'
                        type='time'
                        onChange={(evt) => this.handleHourChange('from', evt.target.value)}
                        value={this.state.from}
                    />
                </label>
                <label className="form-field">
                    To
                    <input
                        aria-label='To'
                        type='time'
                        onChange={(evt) => this.handleHourChange('to', evt.target.value)}
                        value={this.state.to}
                    />
                </label>
            </div>
        )
    }

    render() {
        return (
            <div className='category-container rule-container'>
                {this.renderWeekdays()}
                {this.renderHourSelector()}
            </div>
        )
    }
}