import React from 'react';
import Modal from 'react-modal';

import './ConfirmDeletionModal.css';

interface Props {
    isOpen: boolean,
    title?: string,
    extraText?: string,
    onConfirm: () => any,
    onCancel?: () => any,
    onClose?: () => any,
};
interface State {

};

export default class ConfirmDeletionModal extends React.Component<Props, State> {
    
    private confirm = () => {
        this.props.onConfirm();
        if (this.props.onClose)
            this.props.onClose();
    }

    private cancel= () => {
        if (this.props.onCancel)
            this.props.onCancel();
        if (this.props.onClose)
            this.props.onClose();
    }
    render() {
        let title: string = this.props.title || 'Delete Item'
        return (
            <Modal
                isOpen={this.props.isOpen}
                contentLabel='Confirm deletion'
                overlayClassName='modal-bg'
                className='modal-box panel'
                closeTimeoutMS={150}
                ariaHideApp={false}
                htmlOpenClassName='ReactModal__Html--open'
            >
                <h2 className='error-title'>{title}</h2>
                <p>{this.props.extraText}</p>
                <div className="delete-modal-button-container">
                    <button onClick={this.cancel} className="secondary">Cancel</button>
                    <button onClick={this.confirm} className="delete-button">Delete</button>
                </div>
            </Modal>
        )
    }
}