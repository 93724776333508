import React from "react";

import {
    IoIosImage,
    IoIosAnalytics,
    IoIosSettings,
    IoIosCard,
    IoIosInformationCircle,
} from "react-icons/io";
import { MdRestaurantMenu } from "react-icons/md";
import { History } from "history";
import { FaTruck, FaTable } from "react-icons/fa";

import "./Sidebar.css";
import { completedTutorial } from "../../../api/firestore";

export const Tab = {
    Trucks: "trucks",
    Menu: "menu",
    Images: "images",
    Analytics: "analytics",
    Payment: "payment",
    Settings: "settings",
    Help: "help",
};

interface Props {
    onTabClick: (tab: string) => void;
    history: History;
    doneTutorial: boolean;
    uid: string;
}
interface State {
    activeTab: string;
    doneTutorial: boolean;
}

class Sidebar extends React.Component<Props, State> {
    readonly state = { activeTab: Tab.Trucks, doneTutorial: this.props.doneTutorial };

    componentDidMount() {
        let path: string = this.props.history.location.pathname;
        if (path.includes("/dashboard/")) {
            let location: string = path.replace("/dashboard/", "");
            if (Object.values(Tab).includes(location)) {
                this.setState({ activeTab: location });
                this.props.onTabClick(location);
            }
        }
    }

    handleTabChange = (tab: string) => {
        if (tab === "help") {
            if (!this.props.doneTutorial) {
                this.setState({ doneTutorial: true });
            }
            completedTutorial(this.props.uid);
        }
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
            this.props.history.push("/dashboard/" + tab);
            this.props.onTabClick(tab);
        }
    };

    render() {
        return (
            <div id="sidebar">
                <div className="collapse" id="sidebar-collapse">
                    <a href="/" id="home-link">
                        <img
                            src={require("../../../assets/bannerlogo.png")}
                            alt="PlateChasr logo"
                        />
                    </a>
                    <div
                        onClick={() => {
                            this.handleTabChange("trucks");
                        }}
                        className={`icon-container ${
                            this.state.activeTab === "trucks" ? "active" : ""
                        }`}
                    >
                        <FaTruck className="sidebar-icon" />
                        <p>Trucks</p>
                    </div>
                    <div
                        onClick={() => {
                            this.handleTabChange("menu");
                        }}
                        className={`icon-container ${
                            this.state.activeTab === "menu" ? "active" : ""
                        }`}
                    >
                        <MdRestaurantMenu className="sidebar-icon" />
                        <p>Menu</p>
                    </div>
                    <div
                        onClick={() => {
                            this.handleTabChange("images");
                        }}
                        className={`icon-container ${
                            this.state.activeTab === "images" ? "active" : ""
                        }`}
                    >
                        <IoIosImage className="sidebar-icon" />
                        <p>Images</p>
                    </div>
                    {/* <div onClick={() => {this.handleTabChange('analytics')}} className={`icon-container ${this.state.activeTab==='analytics' ? 'active' : ''}`}>
                        <IoIosAnalytics className='sidebar-icon'/><p>Analytics</p>
                    </div>
                    <div onClick={() => {this.handleTabChange('payment')}} className={`icon-container ${this.state.activeTab==='payment' ? 'active' : ''}`}>
                        <IoIosCard className='sidebar-icon'/><p>Payment</p>
                    </div> */}
                    <div
                        onClick={() => {
                            this.handleTabChange("settings");
                        }}
                        className={`icon-container ${
                            this.state.activeTab === "settings" ? "active" : ""
                        }`}
                    >
                        <IoIosSettings className="sidebar-icon" />
                        <p>Settings</p>
                    </div>
                    <div
                        onClick={() => {
                            this.handleTabChange("help");
                        }}
                        className={`icon-container ${
                            this.state.activeTab === "help" ? "active" : ""
                        }`}
                    >
                        <IoIosInformationCircle className="sidebar-icon" />
                        <p style={{ position: "relative" }}>Help</p>
                    </div>
                </div>
                <nav id="toggler-container" className="navbar navbar-dark">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#sidebar-collapse"
                        aria-controls="sidebar-collapse"
                        aria-expanded="false"
                        aria-label="Toggle sidebar"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>
        );
    }
}

export default Sidebar;
