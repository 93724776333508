import React from "react";
import { History } from "history";
import RegisterForm from "../../components/RegisterForm/RegisterForm";
import "./Register.css";

interface Props {
    history: History;
}

class Register extends React.Component<Props> {
    render() {
        return (
            <div className="bg" id="register-bg">
                <div className="section">
                    <h2 className="section-title">Welcome to PlateChasr</h2>
                    <div className="section-content register-form">
                        <RegisterForm history={this.props.history} />
                        <p className="panel-text text-center">
                            Already have an account?{" "}
                            <a className="link" href="/login">
                                Login.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
