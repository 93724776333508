import React from "react";
import { FoodTruck } from "../../../types/FoodTruck";
import FoodTruckIcon from "../../../assets/FoodTruckIcon.svg";

import CreateFoodTruck from "./CreateFoodTruck/CreateFoodTruck";
import TruckEntry from "./TruckEntry/TruckEntry";
import ConfirmDeletionModal from "../../../components/ConfirmDeletionModal/ConfirmDeletionModal";
import {
    getFoodTrucks,
    addFoodTruck,
    removeFoodTruck,
    changeTruckDeployment,
    updateFoodTruck,
} from "../../../api/firestore";

interface Props {
    truckIDs: string[];
    uid: string;
}
interface State {
    trucks: FoodTruck[];
    loading: boolean;
    creatingFoodTruck: boolean;
    editingTruck: FoodTruck | null;
    confirmDeletion: boolean;
    truckToDelete: string;
}

class TrucksTab extends React.Component<Props, State> {
    readonly state: State = {
        trucks: [],
        loading: true,
        creatingFoodTruck: false,
        editingTruck: null,
        confirmDeletion: false,
        truckToDelete: "",
    };

    componentDidMount() {
        getFoodTrucks(this.props.truckIDs)
            .then((trucks: FoodTruck[] | null) => {
                if (trucks) {
                    this.setState({
                        trucks,
                        loading: false,
                    });
                } else {
                    this.setState({
                        trucks: [],
                        loading: false,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private openCreateForm = (): void => {
        this.setState({
            creatingFoodTruck: true,
            editingTruck: null,
        });
    };

    private handleCreateClose = (truck: FoodTruck | null, edit: boolean) => {
        if (truck) {
            if (edit) {
                // Send firestore the updated truck
                updateFoodTruck(this.props.uid, truck)
                    .then((successful: boolean) => {
                        if (successful) {
                            // replace old truck with new truck
                            this.setState((prevState: State) => {
                                return {
                                    trucks: prevState.trucks.map((oldTruck) => {
                                        if (
                                            oldTruck.truckID === truck.truckID
                                        ) {
                                            return truck;
                                        }
                                        return oldTruck;
                                    }),
                                };
                            });
                        } else {
                            // display an error
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                addFoodTruck(this.props.uid, truck)
                    .then((truckID: string) => {
                        if (truckID) {
                            truck.truckID = truckID;
                            this.setState({
                                trucks: [truck, ...this.state.trucks],
                            });
                        }
                    })
                    .catch((err) => {
                        // display an error
                    });
            }
        } else {
            // the modal window was closed without submitting
        }
        this.setState({
            creatingFoodTruck: false,
            editingTruck: null,
        });
    };

    private openDeletionConfirmationModal = (truckID: string) => {
        this.setState({
            confirmDeletion: true,
            truckToDelete: truckID,
        });
    };

    private closeDeletionConfirmationModal = () => {
        this.setState({
            confirmDeletion: false,
        });
    };

    private deleteTruck = (): void => {
        // Display a confirmation modal since deletion is permanent
        let truckID: string = this.state.truckToDelete;
        removeFoodTruck(this.props.uid, truckID).then(
            (isSuccessful: boolean) => {
                if (!isSuccessful) {
                    // deletion failed
                    // display error
                } else {
                    // Remove the truck visually
                    this.setState((prevState: State) => {
                        return {
                            trucks: prevState.trucks.filter(
                                (deletedTruck) =>
                                    deletedTruck.truckID !== truckID
                            ),
                        };
                    });
                }
            }
        );
    };

    private changeDeployment = (truckID: string, deployed: boolean) => {
        changeTruckDeployment(this.props.uid, truckID, deployed).then(
            (successful: boolean) => {
                if (successful) {
                    // update visually
                    let trucks: FoodTruck[] = [...this.state.trucks];

                    // Find the index of the updated truck
                    let truckIndex: number = trucks.findIndex(
                        (truck) => truck.truckID === truckID
                    );
                    if (truckIndex >= 0) {
                        let truck: FoodTruck = {
                            ...trucks[truckIndex],
                            deployed,
                        };
                        trucks[truckIndex] = truck;
                        this.setState({ trucks });
                    } else {
                        // the truck was not found on the id search
                        // this should *hopefully* never be reached
                    }
                } else {
                    // display error
                }
            }
        );
    };

    private editTruck = (truck: FoodTruck) => {
        // reopen the create modal with the fields prepopulated
        this.setState({
            editingTruck: truck,
            creatingFoodTruck: true,
        });
    };

    private getEmptyListComponent = (): JSX.Element => {
        if (this.state.loading) {
            return <div></div>;
        } else {
            return (
                <div
                    className="container d-flex flex-column align-items-center"
                    id="empty-list-container"
                >
                    <div className="circle-icon">
                        <img src={FoodTruckIcon} alt="No food trucks found" />
                    </div>
                    <h3>Oops! You don't seem to have any food trucks.</h3>
                    <button
                        onClick={() => {
                            this.openCreateForm();
                        }}
                    >
                        Create a Food Truck
                    </button>
                </div>
            );
        }
    };

    render() {
        return (
            <div className="panel">
                {this.state.creatingFoodTruck && (
                    <CreateFoodTruck
                        uid={this.props.uid}
                        itemToEdit={this.state.editingTruck}
                        onClose={this.handleCreateClose}
                    />
                )}
                <ConfirmDeletionModal
                    isOpen={this.state.confirmDeletion}
                    title="Delete Food Truck"
                    extraText='This will permanently delete this food truck. If you are trying to temporarily remove the food truck, consider selecting "Disable" instead'
                    onConfirm={this.deleteTruck}
                    onClose={this.closeDeletionConfirmationModal}
                />
                <div className="row-container">
                    <h2 className="section-title">Trucks</h2>
                    <button
                        onClick={this.openCreateForm}
                        className="button-outline"
                    >
                        Create
                    </button>
                </div>
                {this.state.trucks.length === 0 ? (
                    this.getEmptyListComponent()
                ) : (
                    <table className="truck-table">
                        <thead>
                            <tr>
                                <th className="table-heading">Name</th>
                                <th className="table-heading mobile-hide">
                                    Date Created
                                </th>
                                <th className="table-heading fit">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.trucks.map((truck, index) => {
                                return (
                                    <TruckEntry
                                        key={truck.truckID}
                                        truck={truck}
                                        onDelete={
                                            this.openDeletionConfirmationModal
                                        }
                                        onDeploymentChange={
                                            this.changeDeployment
                                        }
                                        onEdit={this.editTruck}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}

export default TrucksTab;
