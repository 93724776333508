import React from 'react';

import './TruckList.css';
import CardContainer from './CardContainer/CardContainer';
import TruckFilter from './TruckFilter/TruckFilter';
import { getPublicTrucks } from '../../api/firestore';
import { FoodTruck } from '../../types/FoodTruck';
import UserLocationInput from './UserLocationInput/UserLocationInput';
import isEqual from 'lodash.isequal';

interface Props {

}
interface State {
    trucks: {truck: FoodTruck, distance: number}[],
    loadingTrucks: boolean,
    search: string,
    categories: string[],
    radius: number,
    location: {latitude: number, longitude: number} | null
}

export default class TruckList extends React.Component<Props, State> {
    readonly state: State = {
        trucks: [],
        loadingTrucks: true,
        search: '',
        categories: [],
        radius: 50,
        location: null,
    };

    componentDidUpdate(_prevProps: Props, prevState: State) {
        if (!isEqual(prevState.location, this.state.location)) {
            this.fetchTrucks();
        }
    }

    private handleRadiusChange = (radius: number) => {
        this.setState({radius})

        // In the future, we might implement some caching and debouncing
        // But right now, all of the food trucks are loaded on mount so no
        // api call is necessary on radius change
    }

    private setNewLocation = (latitude: number, longitude: number) => {
        this.setState({
            location: { latitude, longitude },
        })
    }

    private fetchTrucks = () => {
        if (!this.state.location) {
            return;
        }        
        getPublicTrucks(this.state.location, 100)
            .then((trucks: {truck: FoodTruck, distance: number}[]) => {
                this.setState({
                    trucks,
                    loadingTrucks: false
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({loadingTrucks: false});
            })
    }

    render() {
        return (
            
            <div id="trucks-list-container">
                <TruckFilter 
                    onRadiusChange={this.handleRadiusChange}
                    onFilterChange={(categories, search) => this.setState({categories, search})}
                />
                <div id="card-container">
                    <div id="title-card">
                        <div className="row-container">
                            <h1>Trucks</h1>
                            <UserLocationInput
                                onLocationChange={this.setNewLocation}
                            />
                        </div>
                    </div>
                    <CardContainer
                        trucks={this.state.trucks}
                        distance={this.state.radius}
                        searchTerm={this.state.search}
                        categories={this.state.categories}
                        loading={this.state.loadingTrucks}
                    />
                </div>
            </div>
        )
    }
}