import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";

interface Props {
    uid: string;
}
interface State {}

export default class SettingsTab extends React.Component<Props, State> {
    private signOut = () => {
        firebase
            .auth()
            .signOut()
            .then(function () {
                return <Redirect to="/login" />;
            })
            .catch(function (error) {});
    };
    render() {
        return (
            <div className="panel">
                <div className="row-container">
                    <h2 className="section-title">Settings</h2>
                    <button onClick={this.signOut} className="button-outline">
                        Sign out
                    </button>
                </div>
                <p>More will be here soon! Thanks for sticking around.</p>
            </div>
        );
    }
}
