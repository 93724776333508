import React from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { IoIosInformationCircleOutline, IoIosAdd, IoIosTrash } from 'react-icons/io';
import { Section } from '../../../../types/Menu';

interface Props {
    onClose: (truck: Section | null, edit: boolean) => void,
    itemToEdit: Section | null,
};
interface State {
    name: string,
    description: string,
};

export default class CreateSection extends React.Component<Props, State> {
    readonly state = {
        name: '',
        description: '',
    };

    componentDidMount() {
        let itemToEdit: Section | null = this.props.itemToEdit;
        if (itemToEdit) {
            let name: string = itemToEdit.name;
            let desc: string = itemToEdit.desc || '';
            this.setState({
                name: name,
                description: desc,
            });
        }
    }

    private cancelSectionCreation = () => {
        this.props.onClose(null, false);
    };

    private publishSection = (evt: any) => {
        evt.preventDefault();

        let edit: boolean = false;
        let section: Section = {
            name: this.state.name,
            desc: this.state.description,
            items: [],
            id: Date.now().toString(), // TODO: find a better way of getting an id
        }

        if (this.props.itemToEdit) {
            section.items = this.props.itemToEdit.items;
            section.id = this.props.itemToEdit.id;
            edit = true;
        }

        this.props.onClose(section, edit);
    }

    private handleInputChange = (event: React.FormEvent<EventTarget>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;

        this.setState({
            [target.name]: value
        } as unknown as Pick<State, keyof State>)
    }

    render() {
        return (
            <Modal
                isOpen={true}
                contentLabel='Create a Section'
                overlayClassName='modal-bg'
                className='modal-box panel'
                closeTimeoutMS={150}
                ariaHideApp={false}
            >
                <ReactTooltip effect='solid'/>
                <div className="row-container">
                    <h2 className="section-title">Create Section</h2>
                    <button onClick={this.cancelSectionCreation} className="button-outline">Cancel</button>
                </div>
                <form onSubmit={this.publishSection} method="POST" id='' className="form create-form">
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Name</label>
                            <IoIosInformationCircleOutline 
                                data-multiline={true}
                                data-tip='This will be the name of the section, such as Beverages, Burgers, or Appetizers.'
                            />
                        </div>
                        <input required type="text" id="name" name="name" value={this.state.name} onChange={this.handleInputChange}></input>
                    </div>
                    <div className="form-field">
                        <div className="tooltip-label">
                            <label>Description</label>
                            <IoIosInformationCircleOutline
                                data-tip='This field is not necessary, but it may be helpful to provide a short description.'
                            />
                        </div>
                        <input type="text" id="description" name="description" value={this.state.description} onChange={this.handleInputChange}/>
                    </div>
                    <button type='submit' className="col-12">{this.props.itemToEdit ? 'Update' : 'Create'}</button>
                </form>
            </Modal>
        )
    }
}