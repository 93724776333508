import React, { SyntheticEvent } from "react";
import firebase, { User } from "firebase";
import { History } from "history";
import { IoIosAlert } from "react-icons/io";

import { createNewUser } from "../../api/firestore";

interface Props {
    history: History;
}

interface State {
    error: boolean;
    errorMessage: string;
}

export default class RegisterForm extends React.Component<Props, State> {
    readonly state = { error: false, errorMessage: "" };

    private handleRegister = (evt: any) => {
        evt.preventDefault();

        // validate input
        let email: string = evt.target.email.value;
        let password: string = evt.target.password.value;
        let name: string = evt.target.name.value;
        let companyName: string = evt.target.companyname.value;

        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((userCred: firebase.auth.UserCredential) => {
                // Create user in firestore
                let user: User | null = userCred.user;
                if (user) {
                    createNewUser(user, name, companyName);
                }

                this.props.history.push("/verify");
            })
            .catch((error: firebase.auth.Error) => {
                let errorCode: string = error.code;
                let errorMessage: string = error.message;

                console.log(errorCode);

                // auth/invalid-email
                // auth/weak-password
                // auth/email-already-in-use

                if (errorCode === "auth/email-already-in-use") {
                    this.setState({
                        errorMessage:
                            "This email already being used by another user. Did you mean to login?",
                    });
                } else if (errorCode === "auth/weak-password") {
                    this.setState({
                        errorMessage: "Make sure your password has 6 characters or more.",
                    });
                } else if (errorCode === "auth/invalid-email") {
                    this.setState({ errorMessage: "This is not a valid email." });
                } else {
                    // This is likely going to be a firestore error
                    this.setState({
                        errorMessage: "Oops! Something went wrong. Please try again later.",
                    });
                }

                this.setState({ error: true });
            });
    };
    render() {
        return (
            <form onSubmit={this.handleRegister} method="POST" className="form">
                {this.state.error ? (
                    <div className="alert-bg error-bg">
                        <div>{this.state.errorMessage}</div> <IoIosAlert className="alert-icon" />
                    </div>
                ) : null}
                <div className="form-field">
                    <label>Name</label>
                    <input
                        required
                        className={this.state.error ? "is-invalid" : ""}
                        type="text"
                        id="name"
                        name="name"
                    ></input>
                </div>
                <div className="form-field">
                    <label>Company Name</label>
                    <input
                        required
                        className={this.state.error ? "is-invalid" : ""}
                        type="text"
                        id="companyname"
                        name="companyname"
                    ></input>
                </div>
                <div className="form-field">
                    <label>Email</label>
                    <input
                        required
                        className={this.state.error ? "is-invalid" : ""}
                        type="text"
                        id="email"
                        name="email"
                    ></input>
                </div>
                <div className="form-field">
                    <label>Password</label>
                    <input
                        required
                        className={this.state.error ? "is-invalid" : ""}
                        type="password"
                        id="password"
                        name="password"
                    ></input>
                </div>
                <button className="col-12">Sign up</button>
            </form>
        );
    }
}
