import React from "react";
import { IoLogoTwitter, IoLogoInstagram, IoMdHeart } from "react-icons/io";
import "./HelpTab.css";

export default class HelpTab extends React.Component {
    render() {
        return (
            <div className="panel">
                <h2 className="section-title">Help</h2>
                <p className="text-high">
                    For a visual tutorial, check out our{" "}
                    <a
                        className="contact-info"
                        href="https://www.youtube.com/playlist?list=PLGLtXy7igBeerJNyVM8T8nyJjLFroODVM"
                        target="_blank"
                    >
                        short videos
                    </a>{" "}
                    showing you how to do a variety of things in the PlateChasr dashboard.
                </p>
                <p className="text-high">
                    We're always looking to make PlateChasr better. If you need help with anything,
                    reach out to us and we'll get back to you as soon as possible. If you'd just
                    like to let us know how we're doing, feel free to reach out to our team for
                    feedback, suggestions, or any questions you might have! Thanks.
                </p>
                <div className="contact-info-container">
                    <a
                        href="https://twitter.com/platechasr"
                        className="contact-info large"
                        target="_blank"
                    >
                        <IoLogoTwitter className="contact-icon" />
                        @platechasr
                    </a>
                    <a
                        href="https://instagram.com/platechasr"
                        className="contact-info large"
                        target="_blank"
                    >
                        <IoLogoInstagram className="contact-icon" />
                        @platechasr
                    </a>
                </div>
                <p className="text-high">
                    Thank you for helping us improve PlateChasr{" "}
                    <IoMdHeart style={{ marginTop: -5 }} />.
                </p>
            </div>
        );
    }
}
