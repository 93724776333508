import React from 'react';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';

import Column from './Column/Column';
import { Menu, Section, MenuItem, Entry } from '../../../../types/Menu';
import './MenuTable.css';

interface Props {
    menus: Menu[],
    createMenu: () => void,
    createSection: (parentMenu: Menu | null) => void,
    createItem: (parentMenu: Menu | null, parentSection: Section | null) => void,
    onEntrySelect: (entry: Menu | Section | MenuItem) => void,
};
interface State {
    selectedMenu: Menu | null,
    selectedSection: Section | null,
    selectedItem: MenuItem | null,
};

export default class MenuTable extends React.Component<Props, State> {
    readonly state: State = {
        selectedMenu: null,
        selectedSection: null,
        selectedItem: null,
    };

    componentDidUpdate(prevProps: Props) {
        // Test if the array was updated so that we don't rerender on every update
        let selectedMenu = cloneDeep(this.state.selectedMenu);
        let selectedSection = cloneDeep(this.state.selectedSection);
        if (!selectedMenu) {
            return;
        }
        let newMenu = this.props.menus.find(menu => menu.id === (selectedMenu as Menu).id);
        // This checks for a menu or section update/delete
        if (!isEqual(newMenu, selectedMenu)) {
            let newMenus = cloneDeep(this.props.menus);
            
            // First we will check if a menu deletion occured
            if (!selectedMenu) {
                return;
            }
            
            if (this.checkForItemDeletion(newMenus, selectedMenu.id)) {
                // A menu deletion occured so we clear the selections
                this.setState({
                    selectedMenu: null,
                    selectedSection: null,
                    selectedItem: null,
                })
                return;
            }

            // Now we check for an update
            let newMenu: Entry | null = this.checkForItemUpdate(newMenus, selectedMenu);
            if (newMenu) {
                this.setState({selectedMenu: newMenu as Menu})
            }

            // Now we check for a section modification
            let parentMenu = newMenus.find(menu => menu.id === (selectedMenu as Menu).id);
            if (!selectedSection || !parentMenu) {
                return;
            }
            
            if (this.checkForItemDeletion(parentMenu.sections, selectedSection.id)) {
                this.setState({
                    selectedSection: null,
                    selectedItem: null,
                })
                return;
            }

            let newSection = this.checkForItemUpdate(parentMenu.sections, selectedSection);
            if (newSection) {
                this.setState({selectedSection: newSection as Section});
            }

        }

        if (!selectedSection || !newMenu) {
            return;
        }
        let newSection = newMenu.sections.find(section => section.id === (selectedSection as Section).id);
        if (!isEqual(newSection, selectedSection)) {
            // Now we check for an item modification
            let selectedItem = cloneDeep(this.state.selectedItem);
            if (!selectedItem || !newSection) {
                return;
            }

            if (this.checkForItemDeletion(newSection.items, selectedItem.id)) {
                this.setState({selectedItem: null});
                return;
            }

            let newItem = this.checkForItemUpdate(newSection.items, selectedItem);
            if (newItem) {
                this.setState({selectedItem: newItem as MenuItem});
            }
            
        }
    }

    componentDidMount() {
        // auto select the first menu
        if (this.props.menus.length > 0) {
            this.setState({
                selectedMenu: this.props.menus[0]
            })
            this.props.onEntrySelect(this.props.menus[0]);
        }
    }

    private checkForItemDeletion = (items: Entry[], itemID: string): boolean => {
        return !items.some(item => {
            if (item.id === itemID) {
                return true;
            }
            return false;
        })
    }

    // Checks if a menu update occured, and if it did, it returns the new menu
    private checkForItemUpdate = (items: Entry[], oldItem: Entry): Entry | null => {
        let newItem: Entry | null = null;
        items.forEach((item: Entry) => {
            if (item.id === oldItem.id) {
                if (!isEqual(item, oldItem)) {
                    newItem = item;
                }
            }
        })
        return newItem;
    }

    private handleEntrySelect = (id: string, col: 'menu' | 'section' | 'item'): void => {
        if (col === 'menu') {
            this.props.menus.forEach(menu => {
                if (menu.id === id) {
                    this.setState({
                        selectedMenu: menu,
                        selectedSection: null,
                        selectedItem: null,
                    });
                    this.props.onEntrySelect(menu);
                }
            });
        } else if (col === 'section') {
            if (this.state.selectedMenu) {
                this.state.selectedMenu.sections.forEach(section => {
                    if (section.id === id) {
                        this.setState({
                            selectedSection: section,
                            selectedItem: null,
                        });
                        this.props.onEntrySelect(section);
                    }
                });
            }
        } else if (col === 'item') {
            if (this.state.selectedSection) {
                this.state.selectedSection.items.forEach(item => {
                    if (item.id === id) {
                        this.setState({
                            selectedItem: item,
                        });
                        this.props.onEntrySelect(item);
                    }
                })
            }
        }
    }

    private handleCreate = (col: 'menu' | 'section' | 'item'): void => {
        if (col === 'menu') {
            this.props.createMenu();
        } else if (col === 'section') {
            this.props.createSection(this.state.selectedMenu);
        } else if (col === 'item') {
            this.props.createItem(this.state.selectedMenu, this.state.selectedSection);
        }
    }

    render() {
        let sectionData: Section[] = [];
        let itemData: MenuItem[] = [];
        let selectedMenuID: string | null = null;
        let selectedSectionID: string | null = null;
        let selectedItemID: string | null = null;
        let sectionDataExpected: boolean = false;
        let itemDataExpected: boolean = false;
        if (this.state.selectedMenu) {
            sectionData = this.state.selectedMenu.sections;
            selectedMenuID = this.state.selectedMenu.id;
            sectionDataExpected = true;
        }
        if (this.state.selectedSection) {
            itemData = this.state.selectedSection.items;
            selectedSectionID = this.state.selectedSection.id;
            itemDataExpected = true;
        }
        if (this.state.selectedItem) {
            selectedItemID = this.state.selectedItem.id;
        }

        return (
            <div className="container-fluid p-0">
                <div className='row no-gutters' id='menu-table'>
                    <Column
                        title='Menus'
                        data={this.props.menus}
                        selectedID={selectedMenuID}
                        onEntrySelect={(id: string) => this.handleEntrySelect(id, 'menu')}
                        onCreateSelect={this.handleCreate}
                    />
                    <Column
                        title='Sections'
                        data={sectionData}
                        selectedID={selectedSectionID}
                        dataExpected={sectionDataExpected}
                        onEntrySelect={(id: string) => this.handleEntrySelect(id, 'section')}
                        onCreateSelect={this.handleCreate}
                    />
                    <Column
                        title='Items'
                        data={itemData}
                        selectedID={selectedItemID}
                        dataExpected={itemDataExpected}
                        onEntrySelect={(id: string) => this.handleEntrySelect(id, 'item')}
                        onCreateSelect={this.handleCreate}
                    />
                </div>
            </div>
        )
    }
}