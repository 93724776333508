import React from "react";
import "./WeeklyTimePicker.css";

interface Props {
    onChange: (hours: { open: string; close: string }[]) => any;
    value: { open: string; close: string }[];
}
interface State {
    openDays: boolean[];
}

// The format is an array of length 7, where Monday is 0 and Sunday is 6.
// If open and close strings are empty, then the truck is closed for that day
export default class WeeklyTimePicker extends React.Component<Props, State> {
    readonly state = {
        openDays: [false, false, false, false, false, false, false],
    };

    componentDidMount() {
        if (this.props.value === [] || !this.props.value) {
            this.props.onChange([
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
            ]);
        } else {
            const openDays = this.props.value.map((day) => {
                return day.open.length > 0 || day.close.length > 0;
            });

            this.setState({ openDays });
        }
    }

    private defaultValue = { open: "7:00 AM", close: "9:00 PM" };

    private handleCheckboxSelect = (index: number, evt: any) => {
        if (index < 0 || index > 6) {
            console.error("Invalid index for day activation");
            return;
        }
        let days = [...this.state.openDays];
        const checked: boolean = evt.target.checked;

        days[index] = checked;

        // add the default value
        if (checked) {
            this.setTime(index, "open", this.defaultValue.open);
            this.setTime(index, "close", this.defaultValue.close);
        } else {
            this.setTime(index, "open", "");
            this.setTime(index, "close", "");
        }

        this.setState({ openDays: days });
    };

    private setTime = (index: number, status: string, value: string) => {
        if (index < 0 || index > 6) {
            console.error("Invalid index for time set");
        }

        let hours = [...this.props.value];
        if (hours.length === 0) {
            hours = [
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
                { open: "", close: "" },
            ];
        }

        if (status === "open") {
            hours[index].open = value;
        } else if (status === "close") {
            hours[index].close = value;
        }

        this.props.onChange(hours);
    };

    private TimeInput = (index: number, status: string) => {
        let currentValue = "open" ? this.defaultValue.open : this.defaultValue.close;
        try {
            currentValue =
                status === "open" ? this.props.value[index].open : this.props.value[index].close;
        } catch {}
        return (
            <label className="time-select-container">
                {status === "open" ? "Opens at" : "Closes at"}
                <select
                    onChange={(evt) => this.setTime(index, status, evt.target.value)}
                    value={currentValue}
                >
                    <option value="12:00 AM">12:00 AM</option>
                    <option value="12:30 AM">12:30 AM</option>
                    <option value="1:00 AM">1:00 AM</option>
                    <option value="1:30 AM">1:30 AM</option>
                    <option value="2:00 AM">2:00 AM</option>
                    <option value="2:30 AM">2:30 AM</option>
                    <option value="3:00 AM">3:00 AM</option>
                    <option value="3:30 AM">3:30 AM</option>
                    <option value="4:00 AM">4:00 AM</option>
                    <option value="4:30 AM">4:30 AM</option>
                    <option value="5:00 AM">5:00 AM</option>
                    <option value="5:30 AM">5:30 AM</option>
                    <option value="6:00 AM">6:00 AM</option>
                    <option value="6:30 AM">6:30 AM</option>
                    <option value="7:00 AM">7:00 AM</option>
                    <option value="7:30 AM">7:30 AM</option>
                    <option value="8:00 AM">8:00 AM</option>
                    <option value="8:30 AM">8:30 AM</option>
                    <option value="9:00 AM">9:00 AM</option>
                    <option value="9:30 AM">9:30 AM</option>
                    <option value="10:00 AM">10:00 AM</option>
                    <option value="10:30 AM">10:30 AM</option>
                    <option value="11:00 AM">11:00 AM</option>
                    <option value="11:30 AM">11:30 AM</option>
                    <option value="12:00 PM">12:00 PM</option>
                    <option value="12:30 PM">12:30 PM</option>
                    <option value="1:00 PM">1:00 PM</option>
                    <option value="1:30 PM">1:30 PM</option>
                    <option value="2:00 PM">2:00 PM</option>
                    <option value="2:30 PM">2:30 PM</option>
                    <option value="3:00 PM">3:00 PM</option>
                    <option value="3:30 PM">3:30 PM</option>
                    <option value="4:00 PM">4:00 PM</option>
                    <option value="4:30 PM">4:30 PM</option>
                    <option value="5:00 PM">5:00 PM</option>
                    <option value="5:30 PM">5:30 PM</option>
                    <option value="6:00 PM">6:00 PM</option>
                    <option value="6:30 PM">6:30 PM</option>
                    <option value="7:00 PM">7:00 PM</option>
                    <option value="7:30 PM">7:30 PM</option>
                    <option value="8:00 PM">8:00 PM</option>
                    <option value="8:30 PM">8:30 PM</option>
                    <option value="9:00 PM">9:00 PM</option>
                    <option value="9:30 PM">9:30 PM</option>
                    <option value="10:00 PM">10:00 PM</option>
                    <option value="10:30 PM">10:30 PM</option>
                    <option value="11:00 PM">11:00 PM</option>
                    <option value="11:30 PM">11:30 PM</option>
                </select>
            </label>
        );
    };

    private TimeSelectContainer = ({ index, day }: { index: number; day: string }) => {
        return (
            <div
                className="row-container day-container"
                style={{ alignItems: "center", maxWidth: 720 }}
            >
                <div className="row-container-start">
                    <label className="text-high day-label">{day}</label>
                    <label className="row-container-start status-checkbox-container text-high">
                        <input
                            type="checkbox"
                            onChange={(evt) => this.handleCheckboxSelect(index, evt)}
                            name="monday-status"
                            id="monday-status"
                            checked={this.state.openDays[index]}
                        ></input>
                        <div>{this.state.openDays[index] ? "Open" : "Closed"}</div>
                    </label>
                </div>
                {this.state.openDays[index] && (
                    <div className="time-selectors-container row-container-start">
                        {this.TimeInput(index, "open")}
                        {this.TimeInput(index, "close")}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        return (
            <React.Fragment>
                {days.map((day, index) => {
                    return <this.TimeSelectContainer key={index} day={day} index={index} />;
                })}
            </React.Fragment>
        );
    }
}
