import React from "react";
import Modal from "react-modal";
import { getFoodTrucks } from "../../../../api/firestore";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { MenuItem, Menu, Section } from "../../../../types/Menu";
import { getMenus } from "../../../../api/firestore-menus";

interface Props {
    onClose: (url: string, imageMap: any) => any;
    uid: string;
    url: string;
}
interface State {
    menus: Menu[];
    loading: boolean;
    menuItems: string[];
}

export default class MenuLinkModal extends React.Component<Props, State> {
    readonly state: State = {
        menus: [],
        loading: true,
        menuItems: [],
    };

    componentDidMount() {
        getMenus(this.props.uid)
            .then((menus) => {
                this.setState({ menus });
            })
            .catch((err) => console.error(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    private onCheckboxChange = (
        evt: any,
        menuID: string,
        sectionID: string,
        menuItemID: string
    ) => {
        let checked: boolean = evt.target.checked;
        if (checked) {
            this.setState((prevState: State) => {
                let newItem = `${menuID}/${sectionID}/${menuItemID}`;
                return {
                    menuItems: [...prevState.menuItems, newItem],
                };
            });
        } else {
            this.setState((prevState: State) => {
                let oldItem = `${menuID}/${sectionID}/${menuItemID}`;
                return {
                    menuItems: prevState.menuItems.filter((item) => item !== oldItem),
                };
            });
        }
    };

    private renderTable = () => {
        if (this.state.menus.length === 0) {
            // show empty list component
        } else {
            return (
                <>
                    <table className="table gallery-table table-hover">
                        <thead>
                            <tr>
                                <th className="gallery-table-header">Items</th>
                                <th className="gallery-table-header text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.menus.map((menu) => {
                                // perhaps theres a more elegant way than the triple map
                                return menu.sections.map((section) => {
                                    return section.items.map((item) => {
                                        return (
                                            <tr className="gallery-table-row" key={item.id}>
                                                <td className="gallery-table-entry">
                                                    <span className="low-text">
                                                        {menu.name} / {section.name} /
                                                    </span>{" "}
                                                    {item.name}
                                                </td>
                                                <td className="table-checkbox-entry">
                                                    <label className="b-contain">
                                                        <input
                                                            type="checkbox"
                                                            name={item.name + "image"}
                                                            aria-label={
                                                                "Attach image to " + item.name
                                                            }
                                                            onChange={(evt) => {
                                                                this.onCheckboxChange(
                                                                    evt,
                                                                    menu.id,
                                                                    section.id,
                                                                    item.id
                                                                );
                                                            }}
                                                        />
                                                        <div className="b-input"></div>
                                                    </label>
                                                </td>
                                            </tr>
                                        );
                                    });
                                });
                            })}
                        </tbody>
                    </table>
                    <button type="submit" className="col-12">
                        Link
                    </button>
                </>
            );
        }
    };

    private cancelLink = () => {
        this.props.onClose("", []);
    };

    private onSubmit = (evt: any) => {
        evt.preventDefault();
        this.props.onClose(this.props.url, this.state.menuItems);
    };

    render() {
        return (
            <Modal
                isOpen={true}
                contentLabel="Link an image to a menu item"
                overlayClassName="modal-bg"
                className="modal-box panel"
                closeTimeoutMS={150}
                ariaHideApp={false}
            >
                <div className="row-container">
                    <h2 className="section-title">Link to Menu Item</h2>
                    <button onClick={this.cancelLink} className="button-outline">
                        Cancel
                    </button>
                </div>
                <form onSubmit={this.onSubmit} method="POST" id="" className="form create-form">
                    <p>
                        Set this image as the thumbnail for any menu item you have. It will be shown
                        next to the item in the app
                    </p>
                    {this.state.loading ? <Spinner /> : this.renderTable()}
                </form>
            </Modal>
        );
    }
}
