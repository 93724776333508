import { MAPQUEST_KEY } from '../key';

// This function gets the coordinates and city name responding to a zip code
export const getCoordsFromZip = (zip: number): Promise<{coordinates: {lat: number, lng: number}, city: string}> => {
    let fetchUrl = `https://www.mapquestapi.com/geocoding/v1/address?key=${MAPQUEST_KEY}&location=${zip}&thumbMaps=false&maxResults=1`;
    return fetch(fetchUrl, {
        mode: 'cors',
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw "Error with fetch occurred";
        })
        .then((data) => {
            let coordinates = data.results[0].locations[0].displayLatLng;
            let city = data.results[0].locations[0].adminArea5;

            return {coordinates, city}
        })
}

// This function gets the city name from coordinates
export const getCityNameFromCoords = (lat: number, lng: number): Promise<string> => {
    let fetchUrl = `https://www.mapquestapi.com/geocoding/v1/reverse?key=${MAPQUEST_KEY}&location=${lat},${lng}&thumbMaps=false&`;
    return fetch(fetchUrl, {
        mode: 'cors',
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw "Error with fetch occurred";
        })
        .then((data) => {
            let city = data.results[0].locations[0].adminArea5;
            return city;
        })
}

export const getCoordsFromAddress = (street: string, city: string, state: string, zip: string) => {
    let address = `${street} ${city}, ${state}, ${zip}`;
    let fetchUrl = `https://www.mapquestapi.com/geocoding/v1/address?key=${MAPQUEST_KEY}&location=${address}&thumbMaps=false&maxResults=1`;
    return fetch(fetchUrl, {
        mode: 'cors',
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw "Error with fetch occurred";
        })
        .then((data) => {
            let coordinates = data.results[0].locations[0].displayLatLng;
            return coordinates;
        })
}