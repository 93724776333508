import React from "react";

interface Props {
    message: string;
}
interface State {}

export default class Announcement extends React.Component<Props, State> {
    readonly state = {};

    render() {
        return (
            <div className="panel">
                <p className="text-high last-text">{this.props.message}</p>
            </div>
        );
    }
}
