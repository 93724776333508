import firebase, { User } from "firebase";
import { FoodTruck } from "../types/FoodTruck";
import { Menu, Section, MenuItem } from "../types/Menu";
import { getUserDoc } from "./firestore";

const convertFirestoreToMenu = (data: any, id: string): Menu | null => {
    if (data) {
        let name: string = data.name;
        let hours: { open: string; close: string }[] = data.hours;
        let sections: Section[] = data.sections;

        return {
            name,
            hours,
            sections,
            id,
        };
    }
    return null;
};

// Specifically, this removes the id field from the Menu object
// as it becomes the document id in firestore
const convertMenuToFirestore = (menu: Menu): object => {
    return {
        name: menu.name,
        hours: [...menu.hours],
        sections: [...menu.sections],
    };
};

// Get the menus from a truck owners subcollection
export const getMenus = (uid: string): Promise<Menu[]> => {
    let menuCollectionRef = getUserDoc(uid).collection("menu");
    let menus: Menu[] = [];

    return menuCollectionRef
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((menuDoc) => {
                let menu: Menu | null = convertFirestoreToMenu(menuDoc.data(), menuDoc.id);
                if (menu) {
                    menus.push(menu);
                }
            });

            return menus;
        })
        .catch(() => {
            return menus;
        });
};

// Add a menu to the owner's subcollection
export const addMenu = (uid: string, menu: Menu): Promise<string> => {
    let menuCollectionRef = getUserDoc(uid).collection("menu");

    return menuCollectionRef
        .add({
            ...convertMenuToFirestore(menu),
        })
        .then((menuDoc) => {
            return menuDoc.id;
        })
        .catch((err) => {
            console.error(err);
            return "";
        });
};

// Update a new menu item
export const updateMenu = (uid: string, menu: Menu): Promise<boolean> => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menu.id);

    // The id nor sections array can't be updated by this function
    let { hours, name } = menu;
    // remove the id field from the object
    return menuDocRef
        .update({
            hours,
            name,
        })
        .then(() => {
            return true;
        })
        .catch((err) => {
            console.error(err);
            return false;
        });
};

// Delete a menu item
export const deleteMenu = (uid: string, menuID: string): Promise<boolean> => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menuID);

    return menuDocRef
        .delete()
        .then(() => {
            // Deletion successful
            return true;
        })
        .catch((err) => {
            console.error(err);
            return false;
        });
};

// Add a section to a menu item
export const addSection = (uid: string, menuID: string, section: Section): Promise<boolean> => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menuID);

    return menuDocRef
        .update({
            sections: firebase.firestore.FieldValue.arrayUnion(section),
        })
        .then(() => {
            return true;
        })
        .catch((err) => {
            console.error(err);
            return false;
        });
};

// Update a section item
export const updateSection = (uid: string, menuID: string, section: Section): Promise<boolean> => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menuID);

    return firebase.firestore().runTransaction((transaction) => {
        return transaction
            .get(menuDocRef)
            .then((menuDoc) => {
                let menu: Menu | null = convertFirestoreToMenu(menuDoc.data(), menuDoc.id);
                if (!menu) {
                    throw "Menu does not exist";
                }

                menu.sections = menu.sections.map((item: Section) => {
                    if (item.id === section.id) {
                        // Only the desc and name can be updated
                        item.desc = section.desc;
                        item.name = section.name;
                    }
                    return item;
                });

                transaction.update(menuDocRef, {
                    sections: menu.sections,
                });
            })
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

// Delete a section from a menu
export const deleteSection = (uid: string, menuID: string, sectionID: string): Promise<boolean> => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menuID);

    return firebase.firestore().runTransaction((transaction) => {
        return transaction
            .get(menuDocRef)
            .then((doc) => {
                let menu: Menu | null = convertFirestoreToMenu(doc.data(), doc.id);

                if (!menu) {
                    throw "Menu does not exist";
                }

                menu.sections = menu.sections.filter((section) => section.id !== sectionID);

                transaction.update(menuDocRef, {
                    sections: menu.sections,
                });
            })
            .then(() => {
                // Transaction ran successfully
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

// Add a menu item to a menu section
export const addMenuItem = (
    uid: string,
    menuID: string,
    sectionID: string,
    menuItem: MenuItem
): Promise<boolean> => {
    let menuDoc = getUserDoc(uid).collection("menu").doc(menuID);

    // This has to be performed as a transaction because the correct section
    // object has to be modified in the array
    return firebase.firestore().runTransaction((transaction) => {
        return transaction
            .get(menuDoc)
            .then((doc) => {
                let menu: Menu | null = convertFirestoreToMenu(doc.data(), doc.id);
                if (!menu) {
                    throw "Menu does not exist";
                }
                let sections: Section[] = menu.sections.map((section) => {
                    if (section.id === sectionID) {
                        // Append the menu item here
                        section.items.push(menuItem);
                        return section;
                    } else {
                        return section;
                    }
                });
                transaction.update(menuDoc, {
                    sections,
                });
            })
            .then(() => {
                // Transaction was successful
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

// Update a section item
export const updateMenuItem = (
    uid: string,
    menuID: string,
    sectionID: string,
    menuItem: MenuItem
): Promise<boolean> => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menuID);

    return firebase.firestore().runTransaction((transaction) => {
        return transaction
            .get(menuDocRef)
            .then((menuDoc) => {
                let menu: Menu | null = convertFirestoreToMenu(menuDoc.data(), menuDoc.id);
                if (!menu) {
                    throw "Menu does not exist";
                }

                menu.sections = menu.sections.map((section: Section) => {
                    if (section.id === sectionID) {
                        // This map is where the item update happens
                        section.items = section.items.map((item: MenuItem) => {
                            if (item.id === menuItem.id) {
                                // id and photoUrl cannot be updated this way
                                item.calories = menuItem.calories;
                                item.desc = menuItem.desc;
                                item.name = menuItem.name;
                                item.price = menuItem.price;
                            }
                            return item;
                        });
                    }
                    return section;
                });

                transaction.update(menuDocRef, {
                    sections: menu.sections,
                });
            })
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

// Delete a menu item from a section
export const deleteMenuItem = (
    uid: string,
    menuID: string,
    sectionID: string,
    itemID: string
): Promise<boolean> => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menuID);
    return firebase.firestore().runTransaction((transaction) => {
        return transaction
            .get(menuDocRef)
            .then((doc) => {
                let menu: Menu | null = convertFirestoreToMenu(doc.data(), doc.id);

                if (!menu) {
                    throw "Menu does not exist";
                }

                menu.sections = menu.sections.map((section) => {
                    if (section.id === sectionID) {
                        section.items = section.items.filter((item) => item.id !== itemID);
                    }
                    return section;
                });

                transaction.update(menuDocRef, {
                    sections: menu.sections,
                });
            })
            .then(() => {
                // Transaction ran successfully
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};

export const addImageToMenuItem = (
    uid: string,
    menuID: string,
    sectionID: string,
    menuItemID: string,
    url: string
) => {
    let menuDocRef = getUserDoc(uid).collection("menu").doc(menuID);

    return firebase.firestore().runTransaction((transaction) => {
        return transaction
            .get(menuDocRef)
            .then((menuDoc) => {
                let menu: Menu | null = convertFirestoreToMenu(menuDoc.data(), menuDoc.id);
                if (!menu) {
                    throw "Menu does not exist";
                }

                menu.sections = menu.sections.map((section: Section) => {
                    if (section.id === sectionID) {
                        // This map is where the item update happens
                        section.items = section.items.map((item: MenuItem) => {
                            if (item.id === menuItemID) {
                                item.photoURL = url;
                            }
                            return item;
                        });
                    }
                    return section;
                });

                transaction.update(menuDocRef, {
                    sections: menu.sections,
                });
            })
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    });
};
