import React from 'react';
import { FoodTruck } from '../../../types/FoodTruck';

import './TruckCard.css';

interface Props {
    truck: FoodTruck,
    distance: number
};
interface State {

};

export default class TruckCard extends React.Component<Props, State> {
    readonly state = {};

    render() {
        return (
            <div className="trucks-card">
                <img className="truck-card-banner" src={this.props.truck.bannerUrl} alt={this.props.truck.name}/>
                <div className="truck-card-content">
                    <h2 className="truck-card-name">{this.props.truck.name}</h2>
                    <div className="truck-card-desc-container">
                        <p className="truck-card-desc">{this.props.truck.desc}</p>
                    </div>
                    <div className="truck-card-food-cat-container">
                        {
                            this.props.truck.foodCategories.map((cat: string) => 
                                <p key={cat} className='truck-card-food-category'>{cat}</p>
                            )
                        }
                    </div>
                    <div className="truck-card-distance-container">
                        <p className="truck-card-distance">{this.props.distance.toFixed(1)} mi</p>
                    </div>
                </div>
            </div>
        )
    }
}